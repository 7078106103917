import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DropDownType } from '../redux/actions/dropDownAction';
import { ExamType } from '../redux/actions/examAction';
import { ExamFacultyType, addExamFaculty, deleteExamFaculty, updateExamFaculty } from '../redux/actions/examFacultyAction';
import { FacultyType } from '../redux/actions/facultyActions';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';
import imgPath from './img/collegeLogo.png';
import * as Feather from 'react-feather';

const ExamFaculty = () => {

    const dispatch = useDispatch<AppDispatch>();

    const examFacultyList = useSelector<StoreState, ExamFacultyType[]>(state => state.examFaculty);
    const facultyList = useSelector<StoreState, FacultyType[]>(state => state.faculty);
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam);
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree);
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline);
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)

    // useEffect(() => {
    //     dispatch(fetchExamFaculty())
    //     dispatch(fetchDropDown())
    // }, [])
    const [editFlag, setEditFlag] = useState(false);

    const [id, setId] = useState<number>(0)
    const [examId, setExamId] = useState<string>('')
    const [examIdError, setExamIdError] = useState<string>('')
    const [degreeId, setDegreeId] = useState<string>('')
    const [degreeIdError, setDegreeIdError] = useState<string>('')
    const [disciplineId, setDisciplineId] = useState<string>('')
    const [disciplineIdError, setDisciplineIdError] = useState<string>('')
    const [facultyId, setFacultyId] = useState<string>('')
    const [facultyIdError, setFacultyIdError] = useState<string>('')
    const [type, setType] = useState<string>('')
    const [typeError, setTypeError] = useState<string>('')
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const [examFacList, setExamFacList] = useState<ExamFacultyType[]>([]);
    const [searchPerformed, setSearchPerformed] = useState(false);

    useEffect(() => {
        setExamFacList(examFacultyList);
    }, [examFacultyList]);


    const filterStudent = () => {
        if (examId !== '') {
            const filteredExamFacultyList = examFacultyList.filter(item => item.examId === parseInt(examId));
            setExamFacList(filteredExamFacultyList)
            setSearchPerformed(true);
        } else {
            toast.error('Please select all filters');
        }
    };

    const addHandler = () => {
        let error = false

        if (examId.length === 0) {
            setExamIdError('Exam is required');
            error = true;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('Degree is required');
            error = true;
        }
        if (disciplineId.length === 0) {
            setDisciplineIdError('Discipline is required');
            error = true;
        }
        if (facultyId.length === 0) {
            setFacultyIdError('Faculty is required');
            error = true;
        }
        if (type.length === 0) {
            setTypeError('Type is required');
            error = true;
        }

        const addData = {
            examId: parseInt(examId),
            degreeId: parseInt(degreeId),
            disciplineId: parseInt(disciplineId),
            facultyId: parseInt(facultyId),
            type: type
        };

        if (!error) {
            dispatch(addExamFaculty(addData)).then(text => {
                toast.success(text)
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        }
    };



    const editHandler = (
        id: number,
        examId: number,
        degreeId: number,
        disciplineId: number,
        facultyId: number,
        type: string
    ) => {
        setEditFlag(true);
        setId(id);
        setExamId(examId?.toString());
        setDegreeId(degreeId?.toString());
        setDisciplineId(disciplineId?.toString());
        setFacultyId(facultyId?.toString());
        setType(type);
    };

    const updateHandler = () => {
        let error = false

        if (examId.length === 0) {
            setExamIdError('Exam is required');
            error = true;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('Degree is required');
            error = true;
        }
        if (disciplineId.length === 0) {
            setDisciplineIdError('Discipline is required');
            error = true;
        }
        if (facultyId.length === 0) {
            setFacultyIdError('Faculty is required');
            error = true;
        }
        if (type.length === 0) {
            setTypeError('Type is required');
            error = true;
        }

        const editData = {
            id: id,
            examId: parseInt(examId),
            degreeId: parseInt(degreeId),
            disciplineId: parseInt(disciplineId),
            facultyId: parseInt(facultyId),
            type: type
        };


        if (!error) {
            dispatch(updateExamFaculty(editData, id)).then(text => {
                toast.success(text)
                clearHandler();
            }).catch(text => {
                toast.error(text)
            })
        }
    };

    const clearHandler = () => {
        setId(0)
        setExamId('')
        setExamIdError('')
        setDegreeId('')
        setDegreeIdError('')
        setDisciplineId('')
        setDisciplineIdError('')
        setFacultyId('')
        setFacultyIdError('')
        setType('')
        setTypeError('')
        setEditFlag(false);
    };

    const confirmDeleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    };

    const deleteHandler = () => {
        if (id !== undefined) {
            dispatch(deleteExamFaculty(id)).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }



    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    


    const getDiscipline = (discipline: any) => {
        const disciplineName = disciplineList.find((dl) => dl.id === parseInt(discipline))?.name
        return disciplineName
    }

    const getExam = (exam: any) => {
        const examName = examList.find((el) => el.id === parseInt(exam))?.title
        return examName
    }

    const getDegree = (degree: any) => {
        const degreeName = degreeList.find((dl) => dl.id === parseInt(degree))?.name
        return degreeName
    }

    const getFaculty = (faculty: any) => {
        const facultyName = facultyList.find((fl) => fl.id === parseInt(faculty))?.name
        return facultyName
    }

    const getFacultyName = (disciplineCourseId: number) => {
        const disciplineCourse = facultyList.find((dc) => dc.id === disciplineCourseId);
        if (disciplineCourse) {
            return `${disciplineCourse.code} - ${disciplineCourse.name}`;
        }
        return 'N/A';
    }

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(14)
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text('Exam Faculty Report', 110, 53, { align: 'center' });
        doc.text(`${getExam(parseInt(examId))}`, 110, 64, { align: "center" });


        autoTable(doc, {
            startY: 70,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            head: [
                [
                    { content: 'S.No.', styles: { halign: 'center' } },
                    { content: 'Degree', styles: { halign: 'center' } },
                    { content: 'Discipline', styles: { halign: 'center' } },
                    { content: 'Faculty', styles: { halign: 'center' } },
                    { content: 'Type', styles: { halign: 'center' } },
                ],
            ],
            body: examFacultyList.map((pe, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: getDegree(pe.degreeId), styles: { halign: 'center' } },
                { content: getDiscipline(pe.disciplineId), styles: { halign: 'center' } },
                { content: getFacultyName(pe.facultyId), styles: { halign: 'center' } },
                { content: pe.type, styles: { halign: 'center' } }
            ]),
            theme: 'grid',
        });



        // Save the PDF
        doc.save('exam_faculty_report.pdf');
    };

    return (
        <PageContainer title="Exam Faculty">
            <Card title={editFlag ? "Edit Exam Faculty" : "Add Exam Faculty"}>
                <div className='container m-0'>
                    <div className="row">

                        <div className="col-4">
                            <label className="mt-2 mb-2">Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={examId}
                                onChange={(e) => {
                                    setExamId(e.target.value)
                                    setExamIdError('')
                                }}
                            >
                                <option value="">Select disciplines</option>
                                {examList.map((ex) => (
                                    <option value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                            {examIdError && <div className="text-danger">{examIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-2 mb-2">Degree <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={degreeId}
                                onChange={(e) => {
                                    setDegreeId(e.target.value)
                                    setDegreeIdError('')
                                }}
                            >
                                <option value="">Select Degree</option>
                                {degreeList.map((deg) => (
                                    <option value={deg.id}>
                                        {deg.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                        </div>


                        <div className="col-4">
                            <label className="mt-2 mb-2">Discipline <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={disciplineId}
                                onChange={(e) => {
                                    setDisciplineId(e.target.value)
                                    setDisciplineIdError('')
                                }}
                            >
                                <option value="">Select Disicpline</option>
                                {disciplineList.map((dis) => (
                                    <option value={dis.id}>
                                        {dis.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-2 mb-2">Faculty <span className="text-danger">*</span> :</label>
                            <select
                                className="form-control"
                                value={facultyId}
                                onChange={(e) => {
                                    setFacultyId(e.target.value)
                                    setFacultyIdError('')
                                }}
                            >
                                <option value="">Select Faculty</option>
                                {facultyList.map((fac) => (
                                    <option value={fac.id}>
                                        {fac.name}
                                    </option>
                                ))}
                            </select>
                            {facultyIdError && <div className="text-danger">{facultyIdError}</div>}
                        </div>


                        <div className="col-4">
                            <label className="mt-2 mb-2">Type <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={type}
                                onChange={(e) => {
                                    setType(e.target.value)
                                    setTypeError('')
                                }}
                            >
                                <option value="">Select Faculty</option>
                                {dropDownList.filter(dd => dd.category === 'Faculty Type').map((fac) => (
                                    <option value={fac.title}>
                                        {fac.title}
                                    </option>
                                ))}
                            </select>
                            {typeError && <div className="text-danger">{typeError}</div>}
                        </div>

                    </div>
                </div>
                <div className=" col mt-3 m-3 d-flex justify-content-end">
                    <button className="btn btn-sm  btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {editFlag ? <button className="btn btn-sm  btn-success" onClick={updateHandler}>Update</button> :
                        <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                </div>
            </Card>
            <CollapseCard title="Exam Faculty">
            <div className="row m-2">
                        <div className="col-md-4">
                            <label className="mt-3 mb-3">Exam <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                value={examId}
                                onChange={(e) => {
                                    setExamId(e.target.value)
                                    setExamIdError('')
                                }}
                            >
                                <option value="">Select Exam</option>
                                {examList.map((ex) => (
                                    <option value={ex.id}>
                                        {ex.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={filterStudent}
                                style={{ width: "10%", marginTop: "57px" }}
                            >
                                Search
                            </button>
                            <button
                                type="button"
                                onClick={handleDownloadPDF}
                                className="btn btn-sm btn-primary"
                                style={{ width: "10%", marginTop: "57px", marginLeft: "20px" }}
                            >
                                <Feather.Download /> PDF
                            </button>
                        </div>  
            </div>
           
                   
                
                <div className="container-fluid" style={{ width: '100%' }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Exam</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Faculty</th>
                                <th>Type</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {examFacList.map((ef, index) => (
                                <tr key={ef.id}>
                                    <td>{index + 1}</td>
                                    <td>{getExam(ef.examId)}</td>
                                    <td>{getDegree(ef.degreeId)}</td>
                                    <td>{getDiscipline(ef.disciplineId)}</td>
                                    <td>{getFaculty(ef.facultyId)}</td>
                                    <td>{ef.type}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-primary"
                                            style={{ marginRight: '10px' }}
                                            onClick={() =>
                                                editHandler(
                                                    ef.id ? ef.id : 0,
                                                    ef.examId,
                                                    ef.degreeId,
                                                    ef.disciplineId,
                                                    ef.facultyId,
                                                    ef.type,
                                                )
                                            }
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => confirmDeleteHandler(ef.id ? ef.id : 0)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
};

export default ExamFaculty;

interface ExamFaculty {
    id: number;
    code: string;
    name: string;
    designation: string;
    institute: string;
    contact_number: string;
    createdBy: string;
    createTimestamp: string;
}
