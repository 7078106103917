import axios from "axios"
import { useState } from "react"
import { Card } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import Option from "../components/Option"
import CollapseCard from "../components/custom/CollapseCard"
import FormInput from "../components/custom/FormInput"
import PageContainer from "../components/custom/PageContainer"
import { apiUrl } from "../config"
import { YearOptions } from "../config/functions"
import { CourseType } from "../redux/actions/courseActions"
import { DegreeType } from "../redux/actions/degreeAction"
import { DisciplineType } from "../redux/actions/disciplineAction"
import { DisciplineCourseType } from "../redux/actions/disciplineCourseAction"
import { ExamType } from "../redux/actions/examAction"
import { StudentMarkType, fetchStudentMarkAction } from "../redux/actions/studentMarkAction"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"

interface DummyDataType {
    name: string
    regNo: string
    dummyRegNo: number
    intMark: number
    intConvertedMark: number
}

interface FilteredStudentType {
    id: number
    registerNo: string
    regulation: number
    departmentRegisterNumber: string
    name: string
    internalMark: string
    intConversionRate: string
}

const DummyNoGeneration = () => {
    const dispatch = useDispatch<AppDispatch>()

    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)
    const studentMarkList = useSelector<StoreState, StudentMarkType[]>(state => state.studentMark)

    const [exam, setExam] = useState<string>('')
    const [examError, setExamError] = useState<string>('')
    const [degree, setDegree] = useState<string>('')
    const [degreeError, setDegreeError] = useState<string>('')
    const [discipline, setDiscipline] = useState<string>('')
    const [disciplineError, setDisciplineError] = useState<string>('')
    const [disciplineCourse, setDisciplineCourse] = useState<string>('')
    const [disciplineCourseError, setDisciplineCourseError] = useState<string>('')
    const [batch, setBatch] = useState<string>('')
    const [batchError, setBatchError] = useState<string>('')
    const [sem, setSem] = useState<string>('')
    const [semError, setSemError] = useState<string>('')
    const [dummyNo, setDummyNo] = useState<string>('')
    const [dummyNoError, setDummyNoError] = useState<string>('')
    const [questionPaperCode, setQuestionPaperCode] = useState<string>('')
    const [questionPaperCodeError, setQuestionPaperCodeError] = useState<string>('')
    const [shuffle, setShuffle] = useState<string>('')
    const [nonShuffle, setNonShuffle] = useState<string>('')
    const [shuffleError, setShuffleError] = useState<string>('')
    const [isShuffled, setIsShuffled] = useState<boolean>(true)
    const yearList = YearOptions();

    const [dummyNoGenData, setDummyNoGenData] = useState<DummyDataType[]>([])
    const [selectedCourseStudents, setSelectedCourseStudents] = useState<FilteredStudentType[]>([])

    const GenerateDummyNoForStudent = (slength: number) => {
        let seqStartingNo = parseInt(dummyNo)
        let dummyArray: number[] = [seqStartingNo]
        for (let index = 1; index < slength; index++) {
            const val = seqStartingNo + index;
            dummyArray.push(val)
        }
        return dummyArray
    }
    const shuffleArray = (array: number[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const AssignDummyNoForStudents = (sdata: FilteredStudentType[], dummyArray: number[]) => {
        const data: DummyDataType[] = []
        const shuffledDummyValues = isShuffled ? shuffleArray(dummyArray) : dummyArray

        sdata.forEach((s, i) => {
            data.push(
                {
                    name: s.name,
                    regNo: s.registerNo,
                    dummyRegNo: shuffledDummyValues[i],
                    intMark: parseInt(s.internalMark),
                    intConvertedMark: (parseInt(s.internalMark) / 100) * parseInt(s.intConversionRate)
                }
            )
        })
        return data

    }

    const clearData = () => {
        setExam('')
        setExamError('')
        setDegree('')
        setDegreeError('')
        setDiscipline('')
        setDisciplineError('')
        setDisciplineCourse('')
        setDisciplineCourseError('')
        setBatch('')
        setBatchError('')
        setSem('')
        setSemError('')
        setDummyNo('')
        setDummyNoError('')
        setQuestionPaperCode('')
        setQuestionPaperCodeError('')
        setSelectedCourseStudents([])
        setDummyNoGenData([])
    }

    const handleSearch = () => {
        if (parseInt(dummyNo) !== 0) {
            if (exam !== '' && degree !== '' && discipline !== '' && batch !== '' && sem !== '' && dummyNo !== '' && questionPaperCode !== '') {
                let disciplineCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse)?.id
                console.log(disciplineCourse);
                console.log(disciplineCourseId);

                // let years = academicYear.split('-')
                axios.get<{ data: FilteredStudentType[] }>(`${apiUrl}/student/?degreeId=${degree}&disciplineId=${discipline}&batch=${batch}&disciplineCourseId=${disciplineCourseId}&sem=${sem}&examId=${exam}`)
                    .then((response) => {
                        const result = response.data.data
                        if (result.length > 0) {
                            setSelectedCourseStudents(result);
                            let dummyData = GenerateDummyNoForStudent(result.length)
                            console.log(dummyData);
                            setDummyNoGenData(AssignDummyNoForStudents(result, dummyData))
                        } else {
                            setSelectedCourseStudents([])
                            setDummyNoGenData([])
                        }
                    })
                    .catch((error) => {
                        toast.error(error);
                        console.error('Error fetching students', error);
                    });
            } else {
                toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });

                setSelectedCourseStudents([])
                setDummyNoGenData([])
            }
        } else toast.error('Invalid Dummy No', { position: 'top-right', autoClose: 3000 });
    }

    const handleAdd = () => {
        let error = false

        let data: StudentMarkType[] = []
        let disciplineCourseId = disciplineCourseList.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse)?.id

        let IfRecordAlreadyExists = studentMarkList?.filter(f => f.examId?.toString() === exam && f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.disciplineCourseId?.toString() === disciplineCourseId?.toString() && f.semester?.toString() === sem)

        if (IfRecordAlreadyExists.length === 0) {

            if (exam === '') {
                setExamError('Exam required');
                error = true
            }
            if (degree === '') {
                setDegreeError('Degree required');
                error = true
            }
            if (discipline === '') {
                setDisciplineError('Discipline required');
                error = true
            }
            if (sem === '') {
                setSemError('Semester required');
                error = true
            }
            if (batch === '') {
                setBatchError('Batch required');
                error = true
            }
            if (dummyNo === '') {
                setDummyNoError('Dummy Number required');
                error = true
            }
            if (disciplineCourse === '') {
                setDisciplineCourseError('Discipline Course required');
                error = true
            }
            if (questionPaperCode === '') {
                setQuestionPaperCodeError('Question Paper Code required');
                error = true
            }

            dummyNoGenData.forEach(d => {
                if (d.regNo !== '') {
                    let isDummyNoAlreadyExists = studentMarkList.filter(f => f.dummyNumber === d.dummyRegNo.toString() && f.examId === parseInt(exam))
                    if (isDummyNoAlreadyExists.length === 0) {

                        data.push(
                            {
                                examId: parseInt(exam),
                                degreeId: parseInt(degree),
                                disciplineId: parseInt(discipline),
                                disciplineCourseId: disciplineCourseId ? disciplineCourseId : 0,
                                questionPaperCode: questionPaperCode,
                                semester: parseInt(sem),
                                batch: batch,
                                registerNumber: d.regNo,
                                dummyNumber: d.dummyRegNo.toString(),
                                intMark: d.intMark,
                                intConvertedMark: d.intConvertedMark
                            }
                        )
                    } else {
                        error = true
                        toast.error('Dummy No. already exists! Try Another sequence')
                        return
                    }

                } else error = true
            })

            if (!error) {
                axios.post(`${apiUrl}/studentMark/dummyInsert/`, data)
                    .then((response) => {
                        // toast.success(response.data.message);
                        toast.success('Student Mark Added Successfully');
                        dispatch(fetchStudentMarkAction())
                        clearData()
                    })
                    .catch((error) => {
                        console.error('Error fetching discipline course', error);
                    });
            }
        } else toast.error('Record already exists in this combination');

    }

    return <>
        <PageContainer title='Dummy No. Generation'>
            <Card>
                <div className="row m-2">
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Exam'
                            name='Exam'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={exam}
                            onChange={(e) => {
                                setExam(e.target.value)
                                setExamError('')
                            }}
                            errorText={examError}
                        >
                            <Option value=''>Select</Option>
                            {examList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.title}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Degree'
                            name='Degree'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={degree}
                            onChange={(e) => {
                                setDegree(e.target.value)
                                setDegreeError('')
                            }}
                            errorText={degreeError}
                        >
                            <Option value=''>Select</Option>
                            {degreeList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.name}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Discipline'
                            name='Discipline'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={discipline}
                            onChange={(e) => {
                                setDiscipline(e.target.value)
                                setDisciplineError('')
                            }}
                            errorText={disciplineError}
                        >
                            <Option value=''>Select</Option>
                            {disciplineList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.name}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <FormInput
                            label='Course'
                            name='Course'
                            labelClassName="required"
                            type='select'
                            containerClass="mt-2"
                            value={disciplineCourse}
                            onChange={(e) => {
                                setDisciplineCourse(e.target.value)
                                setDisciplineCourseError('')
                            }}
                            errorText={disciplineCourseError}
                        >
                            <Option value=''>Select</Option>
                            {courseList?.map((e, i) => {
                                return <Option value={e.id} key={i}>{e.name}</Option>
                            })}
                        </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                            name="Batch"
                            label="Batch"
                            labelClassName="required"
                            value={batch}
                            onChange={(e) => {
                                setBatch(e.target.value)
                                setBatchError('')
                            }}
                            placeholder="Select Batch"
                            containerClass="mt-2"
                            type="select"
                            errorText={batchError}
                        >
                            <option value="">Select Batch</option>
                            {yearList.map((y, i) => {
                                return <Option key={i} value={y}>{y}</Option>
                            })
                            }
                        </FormInput>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                            name="Question Paper Code"
                            label="Question Paper Code"
                            labelClassName="required"
                            value={questionPaperCode}
                            onChange={(e) => {
                                setQuestionPaperCode(e.target.value)
                                setQuestionPaperCodeError('')
                            }}
                            placeholder="Question Paper Code"
                            containerClass="mt-2"
                            errorText={questionPaperCodeError}
                        />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                            name="Sem"
                            label="Sem"
                            labelClassName="required"
                            value={sem}
                            onChange={(e) => {
                                setSem(e.target.value)
                                setSemError('')
                            }}
                            placeholder="Select Sem"
                            containerClass="mt-2"
                            type="select"
                            errorText={semError}
                        >
                            <option value="">Select Semester</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </FormInput>

                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <FormInput
                            name="Dummy No Seq"
                            label="Dummy No Seq"
                            labelClassName="required"
                            value={dummyNo}
                            onChange={(e) => {
                                setDummyNo(e.target.value)
                                setDummyNoError('')
                            }}
                            placeholder="Dummy No Seq"
                            containerClass="mt-2"
                            errorText={dummyNoError}
                            type="number"
                        />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3 d-flex justify-content-between">
                        <FormInput
                            name="Shuffle"
                            label="Shuffle"
                            labelClassName="required form-check"
                            value={shuffle}
                            onChange={() => {
                                setIsShuffled(true)
                                setShuffleError('')
                            }}
                            containerClass="mt-3"
                            errorText={shuffleError}
                            type="checkbox"
                            checked={isShuffled}
                        />
                        <FormInput
                            name="Non-Shuffle"
                            label="Non-Shuffle"
                            labelClassName="required"
                            value={nonShuffle}
                            onChange={() => {
                                setIsShuffled(false)
                                setShuffleError('')
                            }}
                            containerClass="mt-3"
                            errorText={shuffleError}
                            type="checkbox"
                            checked={!isShuffled}
                        />
                    </div>
                </div>
                {/* <div className="row align-items-end "> */}
                <div className="text-right m-4">
                    {/* <div className=" col-12 m-2"> */}
                    <button className='btn btn-sm btn-primary' onClick={handleSearch} >Search</button>
                </div>
                {/* </div> */}
            </Card>
            <CollapseCard title="">
                {/* <div className="container-fluid table-container"> */}
                <div>
                    {/* <div className="row text-right m-2"> */}
                    {dummyNoGenData.length > 0 && <div className="text-right m-2">
                        <button className="btn btn-sm btn-success" onClick={handleAdd}>ADD</button>
                    </div>}
                    {/* </div> */}
                    <table className="table table-success table-striped" style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Name</th>
                                <th>Reg.No.</th>
                                <th>Dummy Reg.No.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dummyNoGenData?.map((row, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{row.name}</td>
                                        <td>{row.regNo}</td>
                                        <td>{row.dummyRegNo}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                </div>
            </CollapseCard>
        </PageContainer >
    </>
}
export default DummyNoGeneration