import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LocalFormatDate } from '../components/common/functions';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import { DropDownType, fetchDropDown } from '../redux/actions/dropDownAction';
import { RegulationType } from '../redux/actions/regulationAction';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';

const Student = () => {

    const [studentList, setStudentList] = useState<StudentType[]>([]);

    const dispatch = useDispatch<AppDispatch>()
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const regulationList = useSelector<StoreState, RegulationType[]>(state => state.regulation)

    useEffect(() => {
        dispatch(fetchDropDown())
    }, [])

    const [editFlag, setEditFlag] = useState(false);
    const [registerNo, setRegisterNo] = useState<string>('')
    const [regulation, setRegulation] = useState<string>('')
    const [departmentRegisterNumber, setDepartmentRegisterNumber] = useState<string>('')
    const [name, setName] = useState<string>('');
    const [fatherName, setFatherName] = useState<string>('');
    const [motherName, setMotherName] = useState<string>('');
    const [guardianName, setGuardianName] = useState<string>('');
    const [bloodGroup, setBloodGroup] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<string>('');
    const [aadharNumber, setAadharNumber] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [pincode, setPincode] = useState<string>('');
    const [emailId, setEmailId] = useState<string>('');
    const [contactNumber, setContactNumber] = useState<string>('');
    const [parentContactNumber, setParentContactNumber] = useState<string>('');
    const [community, setCommunity] = useState<string>('');
    const [caste, setCaste] = useState<string>('');
    const [religion, setReligion] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [nationality, setNationality] = useState<string>('');
    const [motherTongue, setMotherTongue] = useState<string>('');
    const [degreeId, setDegreeId] = useState<string>('');
    const [disciplineId, setDisciplineId] = useState<string>('');
    const [modeOfAdmission, setModeOfAdmission] = useState<string>('');
    const [batch, setBatch] = useState<string>('');
    const [entryLevel, setEntryLevel] = useState<string>('');
    const [noOfYears, setNoOfYears] = useState<string>('');
    const [dateOfJoin, setDateOfJoin] = useState<any>('');
    const [currentSemester, setCurrentSemester] = useState('');

    const [photoUrl, setPhotoUrl] = useState<any | null>({ name: '' });
    const [oldPhotoUrl, setOdlPhotoUrl] = useState('');
    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [registerNoError, setRegisterNoError] = useState('')
    const [regulationError, setRegulationError] = useState('')
    const [departmentRegisterNumberError, setDepartmentRegisterNumberError] = useState('')
    const [nameError, setNameError] = useState('');
    const [fatherNameError, setFatherNameError] = useState('');
    const [motherNameError, setMotherNameError] = useState('');
    const [guardianNameError, setGuardianNameError] = useState('');
    const [bloodGroupError, setBloodGroupError] = useState('');
    const [genderError, setGenderError] = useState('');
    const [dateOfBirthError, setDateOfBirthError] = useState('');
    const [aadharNumberError, setAadharNumberError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [pincodeError, setPincodeError] = useState('');
    const [emailIdError, setEmailIdError] = useState('');
    const [contactNumberError, setContactNumberError] = useState('');
    const [parentContactNumberError, setParentContactNumberError] = useState('');
    const [communityError, setCommunityError] = useState('');
    const [casteError, setCasteError] = useState('');
    const [religionError, setReligionError] = useState('');
    const [stateError, setStateError] = useState('');
    const [nationalityError, setNationalityError] = useState('');
    const [motherTongueError, setMotherTongueError] = useState('');
    const [degreeIdError, setDegreeIdError] = useState('');
    const [disciplineIdError, setDisciplineIdError] = useState('');
    const [modeOfAdmissionError, setModeOfAdmissionError] = useState('');
    const [batchError, setBatchError] = useState('');
    const [entryLevelError, setEntryLevelError] = useState('');
    const [dateOfJoinError, setDateOfJoinError] = useState('');
    const [noOfYearsError, setNoOfYearsError] = useState('');
    const [currentSemesterError, setCurrentSemesterError] = useState('');
    const [photoUrlError, setPhotoUrlError] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    const [selectCourseId, setSelectCourseId] = useState<DisciplineCourseType[]>([]);

    const [selectedDegreeId, setSelectedDegreeId] = useState(''); // Default to empty
    const [selectedDisciplineId, setSelectedDisciplineId] = useState(''); // Default to empty
    const [selectBatch, setSelectBatch] = useState<number>()

    //new field
    const [status, setStatus] = useState<string>('active')
    const [statusError, setStatusError] = useState<string>('')
    const [reason, setReason] = useState<string>('')
    const [reasonError, setReasonError] = useState<string>('')

    //filter view
    const [studentListFilter, setFilteredStudentList] = useState<StudentType[]>([]);
    const [semesterFilter, setSemesterFilter] = useState('');
    const [degreeIdFilter, setDegreeIdFilter] = useState('');
    const [disciplineIdFilter, setDisciplineIdFilter] = useState('');
    const [regulationFilter, setRegulationFilter] = useState('')
    const [batchFilter, setBatchFilter] = useState('');

    // console.log(selectBatch);
    console.log(dateOfBirth);
    console.log(dateOfJoin);

    // useEffect(() => {
    //     axios.get(`${apiUrl}/discipline/`)
    //         .then((response) => {
    //             setSelectedDiscipline(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    // useEffect(() => {
    //     axios.get(`${apiUrl}/degree/`)
    //         .then((response) => {
    //             setSelectedDegree(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    // useEffect(() => {
    //     axios.get(`${apiUrl}/regulation/`)
    //         .then((response) => {
    //             setRegulationList(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/student/`)
            .then((response) => {
                setSelectBatch(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setSelectCourseId(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, []);



    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get<{ data: StudentType[] }>(`${apiUrl}/student/`)
            .then((response) => {
                console.log(response);

                setStudentList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {

        const filteredStudents = studentList.filter((student) => {
            return (
                (degreeIdFilter === '' || student.degreeId?.toString() === degreeIdFilter) &&
                (disciplineIdFilter === '' || student.disciplineId?.toString() === disciplineIdFilter) &&
                (batchFilter === '' || student.batch?.toString() === batchFilter) &&
                (regulationFilter === '' || student.regulation?.toString() === regulationFilter) &&
                (semesterFilter === '' || student.currentSemester?.toString() === semesterFilter)
            );
        });

        // const checkFilteredStudents = filteredStudents.length > 0 ? filteredStudents : studentList;
        setFilteredStudentList(filteredStudents);
    }, [degreeIdFilter, disciplineIdFilter, batchFilter, regulationFilter, semesterFilter, studentList]);

    const addHandler = () => {

        // let error=false
        if (registerNo.length === 0) {
            setRegisterNoError('register number is required');
            return;
        }
        if (departmentRegisterNumber.length === 0) {
            setDepartmentRegisterNumberError('Department Register NumberError is required');
            return;
        }
        if (name.length === 0) {
            setNameError('Name is required');
            return;
        }
        if (fatherName.length === 0) {
            setFatherNameError('father Name is required');
            return;
        }
        if (motherName.length === 0) {
            setMotherNameError('mother Name is required');
            return;
        }
        if (gender.length === 0) {
            setGenderError('gender is required');
            return;
        }
        if (dateOfBirth.length === 0) {
            setDateOfBirthError('date of birth is required');
            return;
        }
        if (aadharNumber.length === 0) {
            setAadharNumberError('Aadhar number is required');
            return;
        }
        if (address.length === 0) {
            setAddressError('address is required');
            return;
        }
        if (pincode.length === 0) {
            setPincodeError('pincode is required');
            return;
        }
        if (emailId.length === 0) {
            setEmailIdError('email id is required');
            return;
        }
        if (contactNumber.length === 0) {
            setContactNumberError('contact number is required');
            return;
        }
        if (parentContactNumber.length === 0) {
            setParentContactNumberError('parent contact number is required');
            return;
        }
        if (religion.length === 0) {
            setReligionError('religion Id is required');
            return;
        }
        if (state.length === 0) {
            setStateError('state is required');
            return;
        }
        if (nationality.length === 0) {
            setNationalityError('nationality is required');
            return;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('degree is required');
            return;
        }
        if (disciplineId.length === 0) {
            setDisciplineIdError('disciplineId is required');
            return;
        }
        if (batch.length === 0) {
            setBatchError('batch is required');
            return;
        }
        if (dateOfJoin.length === 0) {
            setDateOfJoinError('date of join is required');
            return;
        }
        if (currentSemester.length === 0) {
            setCurrentSemesterError('current Semester is required');
            return;
        }
        if (!photoUrl || (photoUrl && photoUrl.name === '')) {
            setPhotoUrlError('Photo Url is required');
            return;
        }
        if (status.length === 0) {
            setStatusError('Status is required');
            return;
        }

        console.log(photoUrl);
        console.log(photoUrlError);

        if (registerNo.length > 15) {
            setRegisterNoError('register no must be within 15 characters');
            return;
        }
        if (name.length > 30) {
            setNameError('Name must be within 30 characters');
            return;
        }
        if (fatherName.length > 30) {
            setFatherNameError('father Name must be within 30 characters');
            return;
        }
        if (gender.length > 10) {
            setGenderError('gender must be within 10 characters');
            return;
        }
        if (dateOfBirth.length > 10) {
            setDateOfBirthError('date of birth must be within 10 characters');
            return;
        }
        if (aadharNumber.length > 12 || aadharNumber.length < 12) {
            setAadharNumberError('aadhar number must be within 12 characters');
            return
        }
        if (address.length > 100) {
            setAddressError('address must be within 100 characters');
            return;
        }
        if (contactNumber.length > 10 || contactNumber.length < 10) {
            setContactNumberError('contact number must be 10 digits only');
            return;
        }
        if (parentContactNumber.length > 10 || parentContactNumber.length < 10) {
            setParentContactNumberError(' parentcontact number 10 digits only');
            return;
        }
        if (pincode.length > 6 || pincode.length < 6) {
            setDegreeIdError('pincode must be 6 digits only');
            return;
        }
        if (degreeId.length > 11) {
            setDegreeIdError('degree id must be within 11 characters');
            return;
        }
        if (disciplineId.length > 11) {
            setDisciplineIdError('discipline id must be within 11 characters');
            return;
        }
        if (batch.length > 4) {
            setBatchError('batch must be within 4 characters');
            return;
        }
        if (dateOfJoin.length > 10) {
            setDateOfJoinError('date of join must be within 10 characters');
            return;
        }
        if (currentSemester.length > 11) {
            setCurrentSemesterError('current semester must be within 11 characters');
            return;
        }
        if (photoUrl.length > 100) {
            setPhotoUrlError('photo url must be within 100 characters');
            return;
        }
        if (reason.length > 200) {
            setReason('Reason must be within 200 characters');
            return;
        }

        // const data = { registerNo, name, fatherName, gender, dateOfBirth, aadharNumber, address, contactNumber, degreeId: selectedDegreeId, disciplineId: selectedDisciplineId, batch, dateOfJoin, currentSemester, photoUrl, createdBy, createTimestamp };

        const birthDate = dateOfBirth.split('-');
        let dataOfBirthInDotFormate = `${birthDate[2]}.${birthDate[1]}.${birthDate[0]}`;

        const data = new FormData();
        data.append('registerNo', registerNo)
        data.append('regulation', regulation)
        data.append('departmentRegisterNumber', departmentRegisterNumber)
        data.append('name', name)
        data.append('fatherName', fatherName)
        data.append('motherName', motherName)
        data.append('guardianName', guardianName)
        data.append('bloodGroup', bloodGroup)
        data.append('gender', gender)
        data.append('dateOfBirth', dataOfBirthInDotFormate)
        data.append('aadharNumber', aadharNumber)
        data.append('address', address)
        data.append('pincode', pincode)
        data.append('emailId', emailId)
        data.append('contactNumber', contactNumber)
        data.append('parentContactNumber', parentContactNumber)
        data.append('community', community)
        data.append('caste', caste)
        data.append('religion', religion)
        data.append('state', state)
        data.append('nationality', nationality)
        data.append('motherTongue', motherTongue)
        data.append('degreeId', degreeId)
        data.append('disciplineId', disciplineId)
        data.append('modeOfAdmission', modeOfAdmission)
        data.append('batch', batch)
        data.append('entryLevel', entryLevel)
        data.append('dateOfJoin', LocalFormatDate(dateOfJoin))
        data.append('noOfYears', noOfYears)
        data.append('currentSemester', currentSemester)
        data.append('photoUrl', photoUrl)
        data.append('status', status)
        data.append('reason', reason)

        console.log(data);

        axios.post(`${apiUrl}/student/`, data, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response) => {
                //  console.log(response);
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                    console.log(response);

                    setApiErrorMessage(response.data.message);
                } else {
                    toast.success(response.data.message, { position: 'top-right' });
                    setRegisterNoError('')
                    setRegulationError('')
                    setDepartmentRegisterNumberError('')
                    setNameError('');
                    setFatherNameError('');
                    setMotherNameError('');
                    setGuardianNameError('');
                    setBloodGroupError('');
                    setGenderError('');
                    setDateOfBirthError('');
                    setAadharNumberError('');
                    setAddressError('');
                    setPincodeError('');
                    setEmailIdError('');
                    setContactNumberError('');
                    setParentContactNumberError('');
                    setCommunityError('');
                    setCasteError('');
                    setReligionError('');
                    setStateError('');
                    setNationalityError('');
                    setMotherTongueError('');
                    setDegreeIdError('')
                    setDisciplineIdError('');
                    setModeOfAdmission('')
                    // Error('');
                    setBatchError('');
                    setEntryLevelError('')
                    setDateOfJoinError('');
                    setNoOfYearsError('')
                    setCurrentSemesterError('');
                    setPhotoUrlError('');
                    setStatusError('');
                    setReasonError('');
                    setApiErrorMessage('');
                    fetchData();
                    clearHandler();
                }
            })
            .catch((error) => {
                toast.error(`${error.response.data.message}`, { position: 'top-right' })
            })
    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/student/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    }


    const editHandler = (id: number, registerNo: string, regulation: string, departmentRegisterNumber: string, name: string, fatherName: string, motherName: string, guardianName: string, bloodGroup: string, gender: string, dateOfBirth: string, aadharNumber: string, address: string, pincode: string, emailId: string, contactNumber: string, parentContactNumber: string, community: string, caste: string, religion: string, state: string, nationality: string, motherTongue: string, degreeId: string, disciplineId: string, modeOfAdmission: string, batch: string, entryLevel: string, dateOfJoin: string, noOfYears: string, currentSemester: string, photoUrl: string, status: string, reason: string, createdBy: string, createTimestamp: string) => {

        // console.log(LocalFormatDate(dateOfBirth));
        console.log(photoUrl);

        setEditFlag(true);
        setId(id);
        setRegisterNo(registerNo);
        setRegulation(regulation);
        setDepartmentRegisterNumber(departmentRegisterNumber);
        setName(name);
        setFatherName(fatherName);
        setMotherName(motherName);
        setGuardianName(guardianName);
        setBloodGroup(bloodGroup);
        setGender(gender.toLowerCase());
        const editBirthdate = dateOfBirth.split('.');
        setDateOfBirth(`${editBirthdate[2]}-${editBirthdate[1]}-${editBirthdate[0]}`);
        setAadharNumber(aadharNumber);
        setAddress(address);
        setPincode(pincode);
        setEmailId(emailId);
        setContactNumber(contactNumber);
        setParentContactNumber(parentContactNumber);
        setCommunity(community.toLowerCase());
        setCaste(caste);
        setReligion(religion);
        setState(state);
        setNationality(nationality);
        setMotherTongue(motherTongue);
        setDegreeId(degreeId);
        setDisciplineId(disciplineId);
        setModeOfAdmission(modeOfAdmission);
        setBatch(batch);
        setEntryLevel(entryLevel);
        const editJoindate = dateOfJoin.split('-');
        setDateOfJoin(`${editJoindate[2]}-${editJoindate[1]}-${editJoindate[0]}`);
        setNoOfYears(noOfYears);
        setCurrentSemester(currentSemester);
        setPhotoUrl({ name: photoUrl });
        setStatus( status === '' ? 'active' : status);
        setReason(reason);
        setCreatedBy(createdBy);
        setCreateTimestamp(createTimestamp);
    }

    const updateHandler = () => {
        if (registerNo.length === 0) {
            setRegisterNoError('Register number is required');
            return;
        }
        if (name.length === 0) {
            setNameError('Name is required');
            return;
        }
        if (departmentRegisterNumber.length === 0) {
            setDepartmentRegisterNumberError('Department Register Number is required');
            return;
        }
        if (fatherName.length === 0) {
            setFatherNameError('Father Name is required');
            return;
        }
        if (gender.length === 0) {
            setGenderError('Gender is required');
            return;
        }
        if (dateOfBirth.length === 0) {
            setDateOfBirthError('Date of birth is required');
            return;
        }
        if (aadharNumber.length === 0) {
            setAadharNumberError('Aadhar number is required');
            return;
        }
        if (address.length === 0) {
            setAddressError('Address is required');
            return;
        }
        if (contactNumber.length === 0) {
            setContactNumberError('Contact number is required');
            return;
        }
        if (degreeId.length === 0) {
            setDegreeIdError('Degree ID is required');
            return;
        }
        if (disciplineId.length === 0) {
            setDisciplineIdError('Discipline ID is required');
            return;
        }
        if (batch.length === 0) {
            setBatchError('Batch is required');
            return;
        }
        // if (dateOfJoin.length === 0) {
        //     setDateOfJoinError('Date of join is required');
        //     return;
        // }
        if (currentSemester.length === 0) {
            setCurrentSemesterError('Current Semester is required');
            return;
        }
        if (photoUrl.length === 0) {
            setPhotoUrlError('Photo Url is required');
            return;
        }
        // if (status.length === 0) {
        //     setStatusError('Status is required');
        //     return;
        // }
        if (registerNo.length > 15) {
            setRegisterNoError('register no must be within 15 characters');
            return;
        }
        if (departmentRegisterNumber.length > 15) {
            setDepartmentRegisterNumberError('Department register number must be within 15 characters');
            return;
        }
        if (name.length > 30) {
            setNameError('Name must be within 30 characters');
            return;
        }
        if (fatherName.length > 30) {
            setFatherNameError('father Name must be within 30 characters');
            return;
        }
        if (gender.length > 10) {
            setGenderError('gender must be within 10 characters');
            return;
        }
        if (dateOfBirth.length > 10) {
            setDateOfBirthError('date of birth must be within 10 characters');
            return;
        }
        if (aadharNumber.length > 15) {
            setAadharNumberError('aadhar number must be within 15 characters');
            return;
        }
        if (address.length > 100) {
            setAddressError('address must be within 100 characters');
            return;
        }
        if (contactNumber.length > 30) {
            setContactNumberError('contact number must be within 30 characters');
            return;
        }
        if (degreeId.length > 11) {
            setDegreeIdError('degree id must be within 11 characters');
            return;
        }
        if (disciplineId.length > 11) {
            setDisciplineIdError('discipline id must be within 11 characters');
            return;
        }
        if (batch.length > 4) {
            setBatchError('batch must be within 4 characters');
            return;
        }
        // if (dateOfJoin.length > 10) {
        //     setDateOfJoinError('date of join must be within 10 characters');
        //     return;
        // }
        if (currentSemester.length > 11) {
            setCurrentSemesterError('current semester must be within 11 characters');
            return;
        }
        // if (photoUrl.length > 100) {
        //   setPhotoUrlError('photo url must be within 100 characters');
        //   return;
        // }
        if (reason && typeof reason === 'string' && reason.length > 200) {
            setReason('Reason must be within 200 characters');
            return;
        }
        // const data = { id, registerNo, name, fatherName, gender, dateOfBirth, aadharNumber, address, contactNumber, degreeId: selectedDegreeId, disciplineId: selectedDisciplineId, batch, dateOfJoin, currentSemester, photoUrl, createdBy, createTimestamp };

        const birthDate = dateOfBirth.split('-');
        let dataOfBirthInDotFormate = `${birthDate[2]}.${birthDate[1]}.${birthDate[0]}`;

        console.log(photoUrl);

        const data = new FormData();
        data.append('registerNo', registerNo)
        data.append('regulation', regulation)
        data.append('departmentRegisterNumber', departmentRegisterNumber)
        data.append('name', name)
        data.append('fatherName', fatherName)
        data.append('motherName', motherName)
        data.append('guardianName', guardianName)
        data.append('bloodGroup', bloodGroup)
        data.append('gender', gender)
        data.append('dateOfBirth', dataOfBirthInDotFormate)
        data.append('aadharNumber', aadharNumber)
        data.append('address', address)
        data.append('pincode', pincode)
        data.append('emailId', emailId)
        data.append('contactNumber', contactNumber)
        data.append('parentContactNumber', parentContactNumber)
        data.append('community', community)
        data.append('caste', caste)
        data.append('religion', religion)
        data.append('state', state)
        data.append('nationality', nationality)
        data.append('motherTongue', motherTongue)
        data.append('degreeId', degreeId)
        data.append('disciplineId', disciplineId)
        data.append('modeOfAdmission', modeOfAdmission)
        data.append('entryLevel', entryLevel)
        data.append('batch', batch)
        data.append('dateOfJoin', LocalFormatDate(dateOfJoin))
        data.append('noOfYears', noOfYears)
        data.append('currentSemester', currentSemester)
        data.append('photoUrl', photoUrl)
        data.append('status', status)
        data.append('reason', reason)
        if (oldPhotoUrl) {
            data.append('oldImageUrl', photoUrl)
        }

        axios.post(`${apiUrl}/student/?id=${id}`, data)
            .then((response) => {
                if (response.data.error) {
                    toast.error(response.data.message, { position: 'top-right' });
                    setApiErrorMessage(response.data.message);
                } else {
                    toast.success(response.data.message, { position: 'top-right' });
                    setRegisterNoError('')
                    setRegulationError('');
                    setDepartmentRegisterNumberError('');
                    setNameError('');
                    setFatherNameError('');
                    setMotherNameError('');
                    setGuardianNameError('');
                    setBloodGroupError('');
                    setGenderError('');
                    setDateOfBirthError('');
                    setAadharNumberError('');
                    setAddressError('');
                    setPincodeError('');
                    setEmailIdError('');
                    setContactNumberError('');
                    setParentContactNumberError('');
                    setCommunityError('');
                    setCasteError('');
                    setReligionError('');
                    setStateError('');
                    setNationalityError('');
                    setMotherTongueError('');
                    setDegreeIdError('');
                    setDisciplineIdError('');
                    setModeOfAdmissionError('');
                    setBatchError('');
                    setEntryLevelError('');
                    setDateOfJoinError('');
                    setNoOfYearsError('');
                    setCurrentSemesterError('');
                    setPhotoUrlError('');
                    setStatusError('');
                    setReasonError('');
                    setApiErrorMessage('');
                    fetchData();
                    setEditFlag(false);
                    clearHandler();
                }
            }).catch(error => {
                toast.error(error.response.data.message, { position: 'top-right' })
            });
    }

    const clearHandler = () => {
        setRegisterNo('')
        setRegulation('');
        setDepartmentRegisterNumber('');
        setName('');
        setFatherName('');
        setMotherName('');
        setGuardianName('');
        setBloodGroup('');
        setGender('');
        setDateOfBirth('');
        setAadharNumber('');
        setAddress('');
        setPincode('');
        setEmailId('');
        setContactNumber('');
        setParentContactNumber('');
        setCommunity('');
        setCaste('');
        setReligion('');
        setState('');
        setNationality('');
        setMotherTongue('');
        setDegreeId('');
        setDisciplineId('');
        setModeOfAdmission('');
        setBatch('');
        setEntryLevel('');
        setDateOfJoin('');
        setNoOfYears('');
        setCurrentSemester('');
        setPhotoUrl({ name: '' });
        setStatus('');
        setReason('');
        setId(0);
        setCreatedBy('');
        setCreateTimestamp('');
        setEditFlag(false);
        setPhotoUrlError('');
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const getDisciplineId = (student: StudentType) => {
        const discipline = disciplineList.find((disc) => disc.id === parseInt(student.disciplineId.toString()));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    };

    const getDegreeId = (student: StudentType) => {
        const degree = degreeList.find((disc) => disc.id === parseInt(student.degreeId.toString()));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    };

    //batch dropdown
    let currentDate = new Date();
    let year = currentDate.getFullYear();

    let min = 0;
    let max = 10;


    let last10Years = [];
    for (let i = min; i <= max; i++) {
        let dropYear = year - i;
        last10Years.push(dropYear);
    }

    // console.log(last10Years);




    return (
        <PageContainer title="Student">
            <Card title={editFlag ? 'Edit Student' : 'Add Student'}>
                <div className='container m-0'>
                    <div className="row">

                        <div className="col-4">
                            <label className="mt-2 mb-2">Register No <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter register No..." type="text" name="registerNo" onChange={(e) => {
                                setRegisterNo(e.target.value)
                                setRegisterNoError('');
                                setApiErrorMessage('');
                            }} value={registerNo} />
                            {registerNoError && <div className="text-danger">{registerNoError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Regulation<span className='text-danger'>*</span> : </label>
                            <select name="" id="" className='form-control' onChange={(e) => {
                                setRegulation(e.target.value)
                                setRegulationError('');
                                setApiErrorMessage('');
                            }} value={regulation}>
                                <option value="">Select Regulation</option>
                                {regulationList.map((sr) => (
                                    <option value={sr.title}>{sr.title}</option>
                                ))
                                }
                            </select>
                            {regulation && <div className="text-danger">{regulationError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">D.No.<span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter department register number..." type="text" name="registerNo" onChange={(e) => {
                                setDepartmentRegisterNumber(e.target.value)
                                setDepartmentRegisterNumberError('');
                                setApiErrorMessage('');
                            }} value={departmentRegisterNumber} />
                            {departmentRegisterNumberError && <div className="text-danger">{departmentRegisterNumberError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Name <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter name..." type="text" name="name" onChange={(e) => {
                                setName(e.target.value)
                                setNameError('');
                                setApiErrorMessage('');
                            }} value={name} />
                            {nameError && <div className="text-danger">{nameError}</div>}</div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">father Name <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter father name..." type="text" name="fatherName" onChange={(e) => {
                                setFatherName(e.target.value)
                                setFatherNameError('');
                                setApiErrorMessage('');
                            }} value={fatherName} />
                            {fatherNameError && <div className="text-danger">{fatherNameError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Mother Name <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter mother name..." type="text" name="motherName" onChange={(e) => {
                                setMotherName(e.target.value)
                                setMotherNameError('');
                                setApiErrorMessage('');
                            }} value={motherName} />
                            {motherNameError && <div className="text-danger">{motherNameError}</div>}
                        </div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">Guardian Name : </label>
                            <input className="form-control" placeholder="Enter guardian name..." type="text" name="guardianName" onChange={(e) => {
                                setGuardianName(e.target.value)
                                setGuardianNameError('');
                                setApiErrorMessage('');
                            }} value={guardianName} />
                            {guardianNameError && <div className="text-danger">{guardianNameError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Blood Group <span className='text-danger'>*</span> : </label>
                            <select
                                className="form-control"
                                value={bloodGroup}
                                onChange={(e) => {
                                    setBloodGroup(e.target.value);
                                    setBloodGroupError('');
                                    setApiErrorMessage('');
                                }}
                            >
                                <option value="" disabled>Select Blood Group</option>
                                {
                                    dropDownList.filter(dl => dl.category === 'Blood Group').map((dd) => {
                                        return <option value={dd.title}>{dd.title}</option>
                                    })
                                }

                                {/* Add more options as needed */}
                            </select>
                            {bloodGroupError && <div className="text-danger">{bloodGroupError}</div>}
                        </div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">gender <span className='text-danger'>*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="gender"
                                onChange={(e) => {
                                    setGender(e.target.value)
                                    setGenderError('');
                                    setApiErrorMessage('');
                                }} 
                                value={gender}
                            >
                                <option value="" selected>Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="trans-gender">Trans-Gender</option>
                            </select>
                            {/* <input className="form-control" placeholder="Enter  gender..." type="select" name="gender"  /> */}
                            {genderError && <div className="text-danger">{genderError}</div>}
                        </div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">Date Of Birth <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter date Of Birth..." type="date" name="dateOfBirth" onChange={(e) => {
                                setDateOfBirth(e.target.value)
                                setDateOfBirthError('');
                                setApiErrorMessage('');
                            }} value={dateOfBirth} />
                            {dateOfBirthError && <div className="text-danger">{LocalFormatDate(dateOfBirthError)}</div>}</div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">aadhar Number <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter aadhar Number..." type="text" name="aadharNumber" onChange={(e) => {
                                setAadharNumber(e.target.value)
                                setAadharNumberError('');
                                setApiErrorMessage('');
                            }} value={aadharNumber} />
                            {aadharNumberError && <div className="text-danger">{aadharNumberError}</div>}</div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">address <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter address..." type="text" name="address" onChange={(e) => {
                                setAddress(e.target.value)
                                setAddressError('');
                                setApiErrorMessage('');
                            }} value={address} />
                            {addressError && <div className="text-danger">{addressError}</div>}</div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Pincode {editFlag ? '' : <span className='text-danger'>*</span>} : </label>
                            <input className="form-control" placeholder="Enter pincode..." type="text" name="pincode" onChange={(e) => {
                                setPincode(e.target.value)
                                setPincodeError('');
                                setApiErrorMessage('');
                            }} value={pincode} />
                            {pincodeError && <div className="text-danger">{pincodeError}</div>}</div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">Email-id <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter email id..." type="text" name="emailId" onChange={(e) => {
                                setEmailId(e.target.value)
                                setEmailIdError('');
                                setApiErrorMessage('');
                            }} value={emailId} />
                            {emailIdError && <div className="text-danger">{emailIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">contact Number <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter contact Number..." type="text" name="contactNumber" onChange={(e) => {
                                setContactNumber(e.target.value)
                                setContactNumberError('');
                                setApiErrorMessage('');
                            }} value={contactNumber} />
                            {contactNumberError && <div className="text-danger">{contactNumberError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Parent contact Number <span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter Parent contact Number..." type="text" name="parentContactNumber" onChange={(e) => {
                                setParentContactNumber(e.target.value)
                                setParentContactNumberError('');
                                setApiErrorMessage('');
                            }} value={parentContactNumber} />
                            {parentContactNumberError && <div className="text-danger">{parentContactNumberError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Community <span className='text-danger'>*</span> : </label>
                            <select
                                className="form-control"
                                value={community}
                                onChange={(e) => {
                                    setCommunity(e.target.value);
                                    setCommunityError('');
                                    setApiErrorMessage('');
                                }}
                            >
                                <option value="" disabled>Select Community</option>
                                {
                                    dropDownList.filter(dl => dl.category === 'Community').map((dd) => {
                                        return <option value={dd.title}>{dd.title.toUpperCase()}</option>
                                    })
                                }


                            </select>
                            {communityError && <div className="text-danger">{communityError}</div>}
                        </div>

                        <div className="col-4">
                           <label className="mt-3 mb-3">Caste {editFlag ? '' : <span className='text-danger'>*</span>} : </label>
                            <select
                                className="form-control"
                                value={caste}
                                onChange={(e) => {
                                    setCaste(e.target.value);
                                    setCasteError('');
                                    setApiErrorMessage('');
                                }}
                            >
                                <option value="" disabled>Select Caste</option>
                                {
                                    dropDownList.filter(dl => dl.category === 'Caste').map((dd) => {
                                        return <option value={dd.title}>{dd.title}</option>
                                    })
                                }

                                {/* Add more options as needed */}
                            </select>
                            {casteError && <div className="text-danger">{casteError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Religion {editFlag ? '' : <span className='text-danger'>*</span>} : </label>
                            <select
                                className="form-control"
                                value={religion}
                                onChange={(e) => {
                                    setReligion(e.target.value);
                                    setReligionError('');
                                    setApiErrorMessage('');
                                }}
                            >
                                <option value="" disabled>Select Religion</option>
                                {
                                    dropDownList.filter(dl => dl.category === 'Religion').map((dd) => {
                                        return <option value={dd.title}>{dd.title}</option>
                                    })
                                }

                            </select>
                            {religionError && <div className="text-danger">{religionError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">State<span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter State..." type="text" name="state" onChange={(e) => {
                                setState(e.target.value)
                                setStateError('');
                                setApiErrorMessage('');
                            }} value={state} />
                            {stateError && <div className="text-danger">{stateError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Nationality<span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter Nationality..." type="text" name="nationality" onChange={(e) => {
                                setNationality(e.target.value)
                                setNationalityError('');
                                setApiErrorMessage('');
                            }} value={nationality} />
                            {nationalityError && <div className="text-danger">{nationalityError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Mother Tongue<span className='text-danger'>*</span> : </label>
                            <input className="form-control" placeholder="Enter Mother Tongue..." type="text" name="motherTongue" onChange={(e) => {
                                setMotherTongue(e.target.value)
                                setMotherTongueError('');
                                setApiErrorMessage('');
                            }} value={motherTongue} />
                            {motherTongueError && <div className="text-danger">{motherTongueError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">degree <span className='text-danger'>*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="degreeId"
                                value={degreeId}
                                onChange={(e) => setDegreeId(e.target.value)}
                            >
                                <option value="" selected>Select Degree</option>
                                {degreeList.map((deg) => (
                                    <option key={deg.id} value={deg.id}>
                                        {deg.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}</div>


                        <div className="col-4">
                            <label className="mt-3 mb-3">discipline <span className='text-danger'>*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="disciplineId"
                                value={disciplineId}
                                onChange={(e) => setDisciplineId(e.target.value)}
                            >
                                <option value="" selected>Select Discipline</option>
                                {disciplineList.map((dis) => (
                                    <option key={dis.id} value={dis.id}>
                                        {dis.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Mode of Admission {editFlag ? '' : <span className='text-danger'>*</span>}  : </label>
                            <select
                                className="form-control"
                                value={modeOfAdmission}
                                onChange={(e) => {
                                    setModeOfAdmission(e.target.value);
                                    setModeOfAdmissionError('');
                                    setApiErrorMessage('');
                                }}
                            >
                                <option value="" disabled>Select Mode of Admission</option>
                                {
                                    dropDownList.filter(dl => dl.category === 'Mode of admission').map((dd) => {
                                        return <option value={dd.title}>{dd.title}</option>
                                    })
                                }

                            </select>
                            {modeOfAdmissionError && <div className="text-danger">{modeOfAdmissionError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">batch <span className='text-danger'>*</span> : </label>
                            {/* <input className="form-control" placeholder="Enter batch..." type="text" name="batch" onChange={(e) => {
                setBatch(e.target.value)
                setBatchError('');
                setApiErrorMessage('');
              }} value={batch} /> */}
                            <select name="" id="" className='form-control' value={batch} onChange={(e) => {
                                setBatch(e.target.value)
                                setBatchError('');
                                setApiErrorMessage('')
                            }}>
                                <option value="">Select Batch</option>
                                {
                                    last10Years.map((ly => {
                                        return <option value={ly}>{ly}</option>
                                    }))
                                    // .sort().map((sb)=>{
                                    //   return <option value={sb.batch}>{sb.batch}</option>
                                    // })
                                }
                            </select>
                            {batchError && <div className="text-danger">{batchError}</div>} </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Entry Level {editFlag ? '' : <span className='text-danger'>*</span>}  : </label>
                            <select
                                className="form-control"
                                value={entryLevel}
                                onChange={(e) => {
                                    setEntryLevel(e.target.value);
                                    setEntryLevelError('');
                                    setApiErrorMessage('');
                                }}
                            >
                                <option value="" disabled>Select Entry Level</option>
                                {
                                    dropDownList.filter(dl => dl.category === 'Entry Level').map((dd) => {
                                        return <option value={dd.title}>{dd.title}</option>
                                    })
                                }

                            </select>
                            {entryLevelError && <div className="text-danger">{entryLevelError}</div>}
                        </div>



                        <div className="col-4">
                            <label className="mt-3 mb-3">date Of Join {editFlag ? '' : <span className='text-danger'>*</span>}  : </label>
                            <input className="form-control" placeholder="Enter date Of Join..." type="date" name="dateOfJoin" onChange={(e) => {
                                setDateOfJoin(e.target.value)
                                setDateOfJoinError('');
                                setApiErrorMessage('');
                            }} value={dateOfJoin} />
                            {dateOfJoinError && <div className="text-danger">{dateOfJoinError}</div>}</div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">No of Years {editFlag ? '' : <span className='text-danger'>*</span>}  : </label>
                            <input className="form-control" placeholder="Enter No of Years..." type="text" name="noOfYears" onChange={(e) => {
                                setNoOfYears(e.target.value)
                                setNoOfYearsError('');
                                setApiErrorMessage('');
                            }} value={noOfYears} />
                            {noOfYearsError && <div className="text-danger">{noOfYearsError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">current Semester <span className='text-danger'>*</span> : </label>
                            <select
                                className="form-control"
                                name="currentSemester"
                                onChange={(e) => {
                                    setCurrentSemester(e.target.value);
                                    setCurrentSemesterError('');
                                    setApiErrorMessage('');
                                }}
                                value={currentSemester}
                            >
                                <option value="">Select Semester</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>
                            {currentSemesterError && <div className="text-danger">{currentSemesterError}</div>} </div>



                        <div className='col-4 custom-file'>
                            <label className="mt-3 mb-3">photoUrl {!editFlag ? <span className='text-danger'>*</span> : ''} : </label>
                            <div className="input-group">
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        placeholder="Enter photo Url..."
                                        name="photoUrl"
                                        onChange={(e) => {
                                            const selectedFile = e.target.files ? e.target.files[0] : null;
                                            if (selectedFile) {
                                                setPhotoUrl(selectedFile);
                                            } else {
                                                console.log('No file selected.');
                                            }
                                            setPhotoUrlError('');
                                            setApiErrorMessage('');
                                        }}
                                        aria-describedby="inputGroupFileAddon01"
                                    // value={photoUrl}
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile01">
                                        {photoUrl.name !== "" ? photoUrl.name : 'Choose file...'}
                                    </label>
                                </div>
                            </div>
                            {photoUrlError && <div className="text-danger">{photoUrlError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Status <span className='text-danger'>*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="status"
                                onChange={(e) => {
                                    setStatus(e.target.value);
                                    setStatusError('');
                                    if (e.target.value === "active") {
                                        setReason('');
                                        setReasonError('');
                                    }
                                }} 
                                value={status}
                            >
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                            {statusError && <div className="text-danger">{statusError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Reason : </label>
                            <input 
                                className="form-control" 
                                placeholder="Enter Reason..." 
                                type="text" 
                                name="reason" 
                                onChange={(e) => {
                                    setReason(e.target.value);
                                    setReasonError('');
                                }} 
                                value={reason} 
                                readOnly={status === "active"} 
                            />
                            {reasonError && <div className="text-danger">{reasonError}</div>}
                        </div>
                        <div className="col mt-5 m-4 d-flex justify-content-end">
                            <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                                Clear
                            </button>
                            {editFlag ? <button className="btn btn-sm btn-success" onClick={updateHandler}>Update</button> :
                                <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>}
                        </div>
                    </div>
                </div>
            </Card>

            <CollapseCard title="Student">
                {/* <div className='container m-3'> */}
                <div className="row m-2 mb-3">
                    <div className="col">
                        <label className="mt-2 mb-2">degree : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="degreeId"
                            value={degreeIdFilter}
                            onChange={(e) => setDegreeIdFilter(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {degreeList.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col">
                        <label className="mt-2 mb-2">discipline : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="disciplineId"
                            value={disciplineIdFilter}
                            onChange={(e) => setDisciplineIdFilter(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {disciplineList.map((dis) => (
                                <option key={dis.id} value={dis.id}>
                                    {dis.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col">
                        <label className="mt-2 mb-2">batch : </label>
                        <select name="" id="" className='form-control' value={batchFilter} onChange={(e) => {
                            setBatchFilter(e.target.value)
                        }}>
                            <option value="">Select Batch</option>
                            {last10Years.map((ly => {
                                return <option key={ly} value={ly}>{ly}</option>
                            }))}
                        </select>
                    </div>

                    <div className="col">
                        <label className="mt-2 mb-2">Regulation : </label>
                        <select name="" id="" className='form-control' onChange={(e) => {
                            setRegulationFilter(e.target.value)
                        }} value={regulationFilter}>
                            <option value="">Select Regulation</option>
                            {regulationList.map((sr) => (
                                <option key={sr.id} value={sr.title}>{sr.title}</option>
                            ))
                            }
                        </select>
                    </div>

                    <div className="col">
                        <label className="mt-2 mb-2">Semester : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedBatch"
                            value={semesterFilter}
                            onChange={(e) => setSemesterFilter(e.target.value)}
                        >
                            <option value="" selected>Select Semester</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </select>
                    </div>
                </div>
                {/* </div> */}

                <div className="container-fluid" style={{ width: "150%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Photo</th>
                                <th>register No & Name & D.No</th>
                                <th>Degree & Discipline</th>
                                <th>Batch  </th>
                                <th>Date Of Birth  </th>
                                <th>gender  </th>
                                <th>blood Group </th>
                                <th>Contact Number  </th>
                                <th>Regulation</th>
                                <th>father Name </th>
                                <th>mother Name </th>
                                <th>guardian Name </th>
                                <th>Aadhar Number  </th>
                                <th>Address  </th>
                                <th>Pincode  </th>
                                <th>Email Id  </th>
                                <th>Parent contact Number  </th>
                                <th>Community</th>
                                <th>Religion</th>
                                <th>Current Semester</th>
                                <th>State</th>
                                <th>Nationality</th>
                                <th>Mother Tongue</th>
                                <th>Mode Of Admission </th>
                                <th>Entry level  </th>
                                <th>Date Of Join  </th>
                                <th>No Of years  </th>
                                <th>Status  </th>
                                <th>Reason </th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentListFilter.map((student, index) => (
                                <tr key={student.id}>
                                    <td>{index + 1}</td>
                                    <td><img src={apiUrl + "/" + student.photoUrl} alt={student.name} className="student-thumbnail" /></td>
                                    <td>{student.registerNo}<br />{student.name}<br />{student.departmentRegisterNumber}</td>
                                    <td>{getDegreeId(student)} {getDisciplineId(student)}</td>
                                    <td>{student.batch}</td>
                                    <td>{student.dateOfBirth}</td>
                                    <td>{student.gender}</td>
                                    <td>{student.bloodGroup}</td>
                                    <td>{student.contactNumber}</td>
                                    <td>{student.regulation}</td>
                                    <td>{student.fatherName}</td>
                                    <td>{student.motherName}</td>
                                    <td>{student.guardianName}</td>
                                    <td>{student.aadharNumber}</td>
                                    <td>{student.address}</td>
                                    <td>{student.pincode}</td>
                                    <td>{student.emailId}</td>
                                    <td>{student.parentContactNumber}</td>
                                    <td>{student.community}</td>
                                    {/* <td>{student.caste}</td> */}
                                    <td>{student.religion}</td>
                                    <td>{student.currentSemester}</td>
                                    <td>{student.state}</td>
                                    <td>{student.nationality}</td>
                                    <td>{student.motherTongue}</td>
                                    <td>{student.modeOfAdmission}</td>
                                    <td>{student.entryLevel}</td>
                                    <td>{student.dateOfJoin}</td>
                                    <td>{student.noOfYears}</td>
                                    <td>{student.status}</td>
                                    <td>{student.reason}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary" onClick={() => editHandler(
                                            student.id,
                                            student.registerNo,
                                            student.regulation?.toString(),
                                            student.departmentRegisterNumber,
                                            student.name,
                                            student.fatherName,
                                            student.motherName,
                                            student.guardianName,
                                            student.bloodGroup,
                                            student.gender,
                                            student.dateOfBirth,
                                            student.aadharNumber,
                                            student.address,
                                            student.pincode.toString(),
                                            student.emailId,
                                            student.contactNumber,
                                            student.parentContactNumber.toString(),
                                            student.community.toUpperCase(),
                                            student.caste,
                                            student.religion,
                                            student.state,
                                            student.nationality,
                                            student.motherTongue,
                                            student.degreeId.toString(),
                                            student.disciplineId.toString(),
                                            student.modeOfAdmission,
                                            student.batch,
                                            student.entryLevel,
                                            student.dateOfJoin,
                                            student.noOfYears.toString(),
                                            student.currentSemester.toString(),
                                            student.photoUrl,
                                            student.status,
                                            student.reason,
                                            student.createdBy,
                                            student.createTimestamp
                                        )} style={{ marginRight: '10px' }}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(student.id)} style={{ marginRight: '10px' }}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Add the ToastContainer for displaying toasts */}
            <ToastContainer position="top-right" />
        </PageContainer>
    );
}

export default Student;

export interface StudentType {
    id: number;
    registerNo: string;
    regulation: number;
    departmentRegisterNumber: string;
    name: string;
    fatherName: string;
    motherName: string;
    guardianName: string;
    bloodGroup: string;
    gender: string;
    dateOfBirth: string;
    aadharNumber: string;
    address: string;
    pincode: number;
    emailId: string;
    contactNumber: string;
    parentContactNumber: number;
    community: string;
    caste: string;
    religion: string;
    state: string;
    nationality: string;
    motherTongue: string;
    degreeId: number;
    disciplineId: number;
    modeOfAdmission: string;
    batch: string;
    entryLevel: string;
    dateOfJoin: string;
    noOfYears: number;
    currentSemester: number;
    photoUrl: string;
    status: string;
    reason: string;
    createdBy: string;
    createTimestamp: string;
}

