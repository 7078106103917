import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { PackingType, addPacking, deletePacking } from '../redux/actions/packingActions';
import { StoreState } from '../redux/reducers';
import { AppDispatch } from '../redux/store';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import imgPath from './img/collegeLogo.png';


const Packing = () => {

    const dispatch = useDispatch<AppDispatch>();
    const packingList = useSelector<StoreState, PackingType[]>(state => state.packing);

    const [packingView, setPackingView] = useState<PackingType[]>([])

    const [editFlag, setEditFlag] = useState(false);
    const [examId, setExamId] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [session, setSession] = useState<string>('');
    const [degreeId, setDegreeId] = useState<string>('');
    const [disciplineId, setDisciplineId] = useState<string>('');
    const [disciplineCourseId, setDisciplineCourseId] = useState<string>('');
    const [questionPaperCode, setQuestionPaperCode] = useState<string>('');
    const [seqNo, setSeqNo] = useState<string>('');
    const [presentList, setPresentList] = useState<string>('');
    const [absentList, setAbsentList] = useState<string>('');
    const [malpracticeList, setMalpracticeList] = useState<string>('');
    const [id, setId] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [examIdError, setExamIdError] = useState<string>('');
    const [dateError, setDateError] = useState<string>('');
    const [sessionError, setSessionError] = useState<string>('');
    const [degreeIdError, setDegreeIdError] = useState<string>('');
    const [disciplineIdError, setDisciplineIdError] = useState<string>('');
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState<string>('');
    const [questionPaperCodeError, setQuestionPaperCodeError] = useState<string>('');
    const [seqNoError, setSeqNoError] = useState<string>('');
    const [presentListError, setPresentListError] = useState<string>('');
    const [absentListError, setAbsentListError] = useState<string>('');
    const [malpracticeListError, setMalpracticeListError] = useState<string>('');
    const [batch, setBatch] = useState<string>('');
    const [batchError, setBatchError] = useState<string>('');
    const [last10Years, setLast10Years] = useState<number[]>([]);

    const [selectedExam, setSelectedExam] = useState<Exam[]>([]);
    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<Course[]>([]);
    const [courseList, setCourseList] = useState<DisciplineCourse[]>([]);

    //testing
    const [selectedRegisters, setSelectedRegisters] = useState<string[]>([]);
    const [examAttendanceList, setExamAttendanceList] = useState<ExamAttendance[]>([]);
    const [filteredExamAttendanceList, setFilteredExamAttendanceList] = useState<ExamAttendance[]>([]);
    const [selectedRegistersPresent, setSelectedRegistersPresent] = useState<string[]>([]);
    const [selectedRegistersAbsent, setSelectedRegistersAbsent] = useState<string[]>([]);
    const [selectedRegistersMalpractice, setSelectedRegistersMalpractice] = useState<string[]>([]);
    const [selectedRegistersSpecialCase, setSelectedRegistersSpecialCase] = useState<string[]>([]);
    const [selectedRegistersSpecialPacking, setSelectedRegistersSpecialPacking] = useState<string[]>([]);

    const [selectExam, setSelectExam] = useState<string>('')
    const [selectDegree, setSelectDegree] = useState<string>('')
    const [selectDiscipline, setSelectDiscipline] = useState<string>('')
    const [selectDate, setSelectDate] = useState<string>('')
    const [selectSession, setSelectSession] = useState<string>('')

    console.log(selectExam);



    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/exam/`)
            .then((response) => {
                setSelectedExam(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching degree', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/course/`)
            .then((response) => {
                setSelectedCourse(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setCourseList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline', error);
            });
    }, [])

    const filterSeatingPlans = () => {
        if (degreeId !== '' && disciplineId !== '' && batch !== '' && disciplineCourseId !== '' && examId !== '' && date !== '' && session !== '' && questionPaperCode !== '') {
            axios.get(`${apiUrl}/examAttendance/`)
                .then((response) => {
                    setExamAttendanceList(response.data.data);
                    console.log(response.data);

                    const filteredPlans = response.data.data.filter((attendance: any) => {
                        return (
                            (degreeId === null || attendance.degreeId.toString() === degreeId) &&
                            (disciplineId === null || attendance.disciplineId.toString() === disciplineId) &&
                            (batch === '' || attendance.batch === batch) &&
                            (session === '' || attendance.session === session) &&
                            (date === '' || attendance.date === date) &&
                            (examId === '' || attendance.examId.toString() === examId)
                        );
                    });
                    setFilteredExamAttendanceList(filteredPlans);
                })
                .catch((error) => {
                    toast.error('Error fetching data', { position: 'top-right', autoClose: 3000 });
                });
        } else {
            toast.error('Select All Filters', { position: 'top-right', autoClose: 3000 });
            setFilteredExamAttendanceList([]);
        }
    };



    const handleClick = (listType: any, registerNo: any) => {
        switch (listType) {
            case 'malpractice':
                if (selectedRegistersMalpractice.includes(registerNo)) {
                    setSelectedRegistersMalpractice((prev) => prev.filter((reg) => reg !== registerNo));
                } else {
                    setSelectedRegistersMalpractice((prev) => [...prev, registerNo]);
                }
                break;

            case 'specialCase':
                if (selectedRegistersSpecialCase.includes(registerNo)) {
                    setSelectedRegistersSpecialCase((prev) => prev.filter((reg) => reg !== registerNo));
                } else {
                    setSelectedRegistersSpecialCase((prev) => [...prev, registerNo]);
                }
                break;

            case 'specialPacking':
                if (selectedRegistersSpecialPacking.includes(registerNo)) {
                    setSelectedRegistersSpecialPacking((prev) => prev.filter((reg) => reg !== registerNo));
                } else {
                    setSelectedRegistersSpecialPacking((prev) => [...prev, registerNo]);
                }
                break;


            default:
                break;
        }
    };

    const addHandler = () => {
        let seqNoCounter = 1;
        const malpracticeListCSV = selectedRegistersMalpractice.join(',');
        const specialCaseListCSV = selectedRegistersSpecialCase.join(',');
        const specialPackingListCSV = selectedRegistersMalpractice.join(',');

        const getDisciplineCourseId = (degreeId: string, disciplineId: string, disciplineCourseId: string) => {
            const selectedCourseIdInt = parseInt(disciplineCourseId);
            const selectedDisciplineIdInt = parseInt(disciplineId);
            const selectedDegreeIdInt = parseInt(degreeId);
            const course = selectedCourse.find((c) => c.id === selectedCourseIdInt);
            const discipline = selectedDiscipline.find((c) => c.id === selectedDisciplineIdInt);
            const degree = selectedDegree.find((c) => c.id === selectedDegreeIdInt);
            if (course && discipline && degree) {
                const foundCourse = courseList.find((disciplineCourse) => disciplineCourse.courseId == course.id.toString() && disciplineCourse.disciplineId == discipline.id.toString() && disciplineCourse.degreeId == degree.id.toString());
                if (foundCourse) {
                    return foundCourse.id;
                }
            }
            return 'N/A';
        };

        const disciplineCoursId = getDisciplineCourseId(degreeId, disciplineId, disciplineCourseId);

        const totalAttendanceCount = filteredExamAttendanceList.length;

        const registerNum = filteredExamAttendanceList
            .map((attendance) => attendance.registerNo)
            .join(',');

        const maxAttendeesPerRecord = 50;
        const recordCount = Math.ceil(totalAttendanceCount / maxAttendeesPerRecord);

        filteredExamAttendanceList.sort((a, b) => a.registerNo.localeCompare(b.registerNo));

        for (let i = 0; i < recordCount; i++) {
            const startIdx = i * maxAttendeesPerRecord;
            const endIdx = Math.min((i + 1) * maxAttendeesPerRecord, totalAttendanceCount);

            const splitRegisterNo = filteredExamAttendanceList.slice(startIdx, endIdx);

            const presentListSlice = splitRegisterNo
                .filter((attendance) => attendance.attendance === 'PR')
                .map((attendance) => attendance.registerNo)
                .sort()
                .join(',');

            const absentListSlice = splitRegisterNo
                .filter((attendance) => attendance.attendance === 'AB')
                .map((attendance) => attendance.registerNo)
                .sort()
                .join(',');

            const malpracticeListSlice = malpracticeListCSV
                .split(',')
                .filter((registerNo) => presentListSlice.includes(registerNo))
                .join(',');

            const specialCaseListSlice = specialCaseListCSV
                .split(',')
                .filter((registerNo) => presentListSlice.includes(registerNo))
                .join(',');

            const specialPackingListSlice = specialPackingListCSV
                .split(',')
                .filter((registerNo) => presentListSlice.includes(registerNo))
                .join(',');

            const seqNo = seqNoCounter++;

            const data = {
                examId,
                date,
                session,
                degreeId,
                disciplineId,
                disciplineCourseId: disciplineCoursId.toString(),
                questionPaperCode,
                seqNo: seqNo.toString(),
                presentList: presentListSlice,
                absentList: absentListSlice,
                malpracticeList: malpracticeListSlice,
                specialCaseList: specialCaseListSlice,
                specialPackingList: specialPackingListSlice
            };

            // Dispatch the action to add the record
            dispatch(addPacking(data)).then((text) => {
                toast.success(text);

                clearHandler();
            }).catch((text) => {
                toast.error(text);
            })
        }
    };



    const confirmDeleteHandler = (disciplineId: string) => {
        console.log(disciplineId);

        setId(disciplineId);
        openDeleteConfirmation();
    };

    const deleteHandler = () => {
        console.log(disciplineId);

        if (id !== undefined) {
            console.log(id);
            dispatch(deletePacking(parseInt(id))).then(text => {
                toast.success(text)
                closeDeleteConfirmation();
            }).catch(text => {
                toast.error(text);
            })
        }
    }

    const clearHandler = () => {
        setId('');
        setEditFlag(false);
        setExamId('');
        setDate('');
        setSession('');
        setDegreeId('');
        setDisciplineId('');
        setDisciplineCourseId('');
        setQuestionPaperCode('');
        setBatch('');
        setSeqNo('');
        setPresentList('');
        setAbsentList('');
        setMalpracticeList('');
        setExamIdError('');
        setDateError('');
        setSessionError('');
        setDegreeIdError('');
        setDisciplineIdError('');
        setDisciplineCourseIdError('');
        setQuestionPaperCodeError('');
        setSeqNoError('');
        setPresentListError('');
        setAbsentListError('');
        setMalpracticeListError('');
        setSelectedRegistersMalpractice([]);
        setSelectedRegistersSpecialCase([]);
        setSelectedRegistersSpecialPacking([]);
        setFilteredExamAttendanceList([]);
    }

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const getExamName = (examId: string) => {
        const exam = selectedExam?.find((c) => c.id === parseInt(examId));
        if (exam) {
            return exam.title;
        }
        return 'N/A';
    }

    const getDegreeName = (degreeId: string) => {
        const degree = selectedDegree?.find((c) => c.id === parseInt(degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }

    const getDisciplineName = (disciplineId: string) => {
        const discipline = selectedDiscipline?.find((c) => c.id === parseInt(disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }

    const getCourseName = (courseId: string) => {
        const disciplineCourse = courseList?.find((dc) => dc.id === parseInt(courseId));
        if (disciplineCourse) {
            const course = selectedCourse?.find((c) => c.id === parseInt(disciplineCourse.courseId));
            if (course) {
                return course.name;
            }
        }
        return 'N/A';
    };

    const formatDate = (inputDate: string) => {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    };

    useEffect(() => {
        setPackingView(packingList)
    }, [packingList])


    const handleSearch = () => {

        const filterView = packingList.filter(pl => (
            pl.examId?.toString() === (selectExam !== '' ? selectExam : pl.examId?.toString()) &&
            pl.degreeId?.toString() === (selectDegree !== '' ? selectDegree : pl.degreeId?.toString()) &&
            pl.disciplineId?.toString() === (selectDiscipline !== '' ? selectDiscipline : pl.disciplineId?.toString()) &&
            pl.date?.toString() === (selectDate !== '' ? selectDate : pl.date?.toString()) &&
            pl.session?.toString() === (selectSession !== '' ? selectSession : pl.session?.toString())

            // console.log(pl.examId)
        ))
        setPackingView(filterView)

        // console.log(selectExam)

    }

    const resetData = () => {
        setSelectExam('')
        setSelectDegree('')
        setSelectDiscipline('')
        setSelectDate('')
        setSelectSession('')
        setPackingView(packingList)
    }

    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(16);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text('PACKING GALLEY', 100, 55, { align: 'center' });

        autoTable(doc, {
            startY: 70,
            margin: 5,
            // tableWidth: 193,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            head: [
                [
                    { content: 'S.No.', styles: { halign: 'center' } },
                    // { content: 'Code', styles: { halign: 'center' } },
                    // { content: 'Exam', styles: { halign: 'center' } },
                    // { content: 'Date', styles: { halign: 'center' } },
                    // { content: 'Session', styles: { halign: 'center' } },
                    { content: 'Degree', styles: { halign: 'center' } },
                    { content: 'Discipline', styles: { halign: 'center' } },
                    { content: 'Discipline Course', styles: { halign: 'center' } },
                    // { content: 'Question Paper Code', styles: { halign: 'center' } },
                    // { content: 'Seq.No', styles: { halign: 'center' } },
                    { content: 'Present List', styles: { halign: 'center' } },
                    { content: 'Absent List', styles: { halign: 'center' } },
                    { content: 'Malpractice List', styles: { halign: 'center' } },
                    { content: 'Special Case List', styles: { halign: 'center' } },
                    { content: 'Special Packing List', styles: { halign: 'center' } },

                ],
            ],
            body: packingView.map((pl, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                // { content: pl.code, styles: { halign: 'center' } },
                // { content: getExamName(pl.examId), styles: { halign: 'center' } },
                // { content: pl.date, styles: { halign: 'center' } },
                // { content: pl.session, styles: { halign: 'center' } },
                { content: getDegreeName(pl.degreeId), styles: { halign: 'center' } },
                { content: getDisciplineName(pl.disciplineId), styles: { halign: 'center' } },
                { content: getCourseName(pl.disciplineCourseId), styles: { halign: 'center' } },
                // { content: pl.questionPaperCode, styles: { halign: 'center' } },
                // { content: pl.seqNo, styles: { halign: 'center' } },
                { content: pl.presentList, styles: { halign: 'center' } },
                { content: pl.absentList, styles: { halign: 'center' } },
                { content: pl.malpracticeList, styles: { halign: 'center' } },
                { content: pl.specialCaseList, styles: { halign: 'center' } },
                { content: pl.specialPackingList, styles: { halign: 'center' } },
            ]),
            theme: 'grid',
            columnStyles: {
                0: { cellWidth: 8 },
                // 1: { cellWidth: 12 },
                // 2: { cellWidth: 15 },
                // 3: { cellWidth: 11 },
                // 4: { cellWidth: 17 },
                1: { cellWidth: 17 },
                2: { cellWidth: 25 },
                3: { cellWidth: 22.5 },
                // 8: { cellWidth: 16 },
                // 9: { cellWidth: 17 },
                // 10: { cellWidth: 17 },
                4: { cellWidth: 25.6 },
                5: { cellWidth: 25.6 },
                6: { cellWidth: 25.6 },
                7: { cellWidth: 25.6 },
                8: { cellWidth: 25.6 }
            },
        });
        doc.save('packing_galley' + '.pdf');
    }

    const csvData = [
        [
            'S.No.',
            'Code',
            'Exam',
            'Date',
            'Session',
            'Degree',
            'Discipline',
            'Disicipline Course',
            'Question Paper Code',
            'Seq No',
            'Present List',
            'Absent List',
            'Malpractice List',
            'Special List',
            'Special Packing List',
        ],
        ...packingView.map((row, i) => [
            (i + 1),
            row.code,
            getExamName(row.examId),
            row.date,
            row.session,
            getDegreeName(row.degreeId),
            getDisciplineName(row.disciplineId),
            getCourseName(row.disciplineCourseId),
            row.questionPaperCode,
            row.seqNo,
            row.presentList,
            row.absentList,
            row.malpracticeList,
            row.specialCaseList,
            row.specialPackingList
        ]),
    ];




    return (
        <PageContainer title="Packing Galley">
            <Card title={editFlag ? "Edit Packing Galley" : "Add Packing Galley"}>
                <div className="row">
                    <div className="col-4">
                        <label className="mt-3 mb-3">Exam <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="exam"
                            value={examId}
                            onChange={(e) => setExamId(e.target.value)}
                        >
                            <option value="" selected>Select Exam</option>
                            {selectedExam.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                        {examIdError && <div className="text-danger">{examIdError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Date <span className="text-danger">*</span> : </label>
                        <input
                            className="form-control"
                            placeholder="Enter date..."
                            type="date"
                            name="date"
                            onChange={(e) => setDate(e.target.value)}
                            value={date}
                        />
                        {dateError && <div className="text-danger">{dateError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Session <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="session"
                            value={session}
                            onChange={(e) => setSession(e.target.value)}
                        >
                            <option value="" selected>Select Session</option>
                            <option value="AN" selected>AN</option>
                            <option value="FN" selected>FN</option>
                        </select>
                        {sessionError && <div className="text-danger">{sessionError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Batch <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedBatch"
                            value={batch}
                            onChange={(e) => setBatch(e.target.value)}
                        >
                            <option value="" selected>Select Batch</option>
                            {last10Years.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {batchError && <div className="text-danger">{batchError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Degree <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedDegree"
                            value={degreeId}
                            onChange={(e) => setDegreeId(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {selectedDegree.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                        {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Discipline <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectedDiscipline"
                            value={disciplineId}
                            onChange={(e) => setDisciplineId(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {selectedDiscipline.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                        {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                    </div>


                    <div className="col-4">
                        <label className="mt-3 mb-3">Course <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="disciplineCourseId"
                            value={disciplineCourseId}
                            onChange={(e) => setDisciplineCourseId(e.target.value)}
                        >
                            <option value="" selected>Select Course</option>
                            {selectedCourse.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                        {disciplineCourseIdError && <div className="text-danger">{disciplineCourseIdError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Question Paper Code <span className="text-danger">*</span> : </label>
                        <input
                            className="form-control"
                            placeholder="Enter question paper code..."
                            type="text"
                            name="questionPaperCode"
                            onChange={(e) => setQuestionPaperCode(e.target.value)}
                            value={questionPaperCode}
                        />
                        {questionPaperCodeError && <div className="text-danger">{questionPaperCodeError}</div>}
                    </div>

                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={filterSeatingPlans}
                            style={{ width: "10%", marginTop: "50px", marginBottom: "50px", float: "right" }}
                        >
                            Search
                        </button>
                    </div>

                    <div className="col-6">
                        <label className="mt-3 mb-3">Present List:</label>
                        <div className="row m-2">
                            {filteredExamAttendanceList
                                .filter((attendance) => attendance.attendance === 'PR')
                                .map((attendance, index) => (
                                    <div className="col-4 p-1" key={index}>
                                        <div
                                            style={{
                                                backgroundColor: 'green',
                                                color: 'white',
                                            }}
                                            className="border text-center p-1"
                                        >
                                            {attendance.registerNo}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                    <div className="col-6">
                        <label className="mt-3 mb-3">Absent List:</label>
                        <div className="row m-2">
                            {filteredExamAttendanceList
                                .filter((attendance) => attendance.attendance === 'AB')
                                .map((attendance, index) => (
                                    <div className="col-4 p-1" key={index}>
                                        <div
                                            style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                            }}
                                            className="border text-center p-1"
                                        >
                                            {attendance.registerNo}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>


                    <div className="col-6">
                        <label className="mt-3 mb-3">Malpractice List (select) :</label>
                        <div className="row m-2">
                            {filteredExamAttendanceList
                                .filter((attendance) => attendance.attendance === 'PR')
                                .map((attendance, index) => (
                                    <div className="col-4 p-1" key={index}>
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: selectedRegistersMalpractice.includes(attendance.registerNo) ? "yellowGreen" : "",
                                                color: selectedRegistersMalpractice.includes(attendance.registerNo) ? "white" : "",
                                            }}
                                            className="border text-center p-1"
                                            onClick={() => handleClick('malpractice', attendance.registerNo)}
                                        >
                                            {attendance.registerNo}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>


                    <div className="col-6">
                        <label className="mt-3 mb-3">Special Case (select) :</label>
                        <div className="row m-2">
                            {filteredExamAttendanceList
                                .filter((attendance) => attendance.attendance === 'PR')
                                .map((attendance, index) => (
                                    <div className="col-4 p-1" key={index}>
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: selectedRegistersSpecialCase.includes(attendance.registerNo) ? "yellowGreen" : "",
                                                color: selectedRegistersSpecialCase.includes(attendance.registerNo) ? "white" : "",
                                            }}
                                            className="border text-center p-1"
                                            onClick={() => handleClick('specialCase', attendance.registerNo)}
                                        >
                                            {attendance.registerNo}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>


                    <div className="col-6">
                        <label className="mt-3 mb-3">Special Packing (select) :</label>
                        <div className="row m-2">
                            {filteredExamAttendanceList
                                .filter((attendance) => attendance.attendance === 'PR')
                                .map((attendance, index) => (
                                    <div className="col-4 p-1" key={index}>
                                        <div
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: selectedRegistersSpecialPacking.includes(attendance.registerNo) ? "yellowGreen" : "",
                                                color: selectedRegistersSpecialPacking.includes(attendance.registerNo) ? "white" : "",
                                            }}
                                            className="border text-center p-1"
                                            onClick={() => handleClick('specialPacking', attendance.registerNo)}
                                        >
                                            {attendance.registerNo}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>

                </div>

                <div className=" col mt-5 m-4 d-flex justify-content-end">
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>
                </div>

            </Card>
            <CollapseCard title="Packing Galley">
                <div className="row m-1">
                    <div className="col-4">
                        <label className="mt-2 mb-1">Exam  </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="exam"
                            value={selectExam}
                            onChange={(e) => setSelectExam(e.target.value)}
                        >
                            <option value="" selected>Select Exam</option>
                            {selectedExam.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-1">Degree :</label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedDegree"
                            value={selectDegree}
                            onChange={(e) => setSelectDegree(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {selectedDegree.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-1">Discipline  : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectedDiscipline"
                            value={selectDiscipline}
                            onChange={(e) => setSelectDiscipline(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {selectedDiscipline.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-1">Date  : </label>
                        <input
                            className="form-control"
                            placeholder="Enter date..."
                            type="date"
                            name="date"
                            onChange={(e) => setSelectDate(e.target.value)}
                            value={selectDate}
                        />
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-1">Session  : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px", }}
                            name="selectSession"
                            value={selectSession}
                            onChange={(e) => setSelectSession(e.target.value)}
                        >
                            <option value="" selected>Select Session</option>
                            <option value="AN" selected>AN</option>
                            <option value="FN" selected>FN</option>
                        </select>
                    </div>

                    {/* <div>
                        <div className=" col-4 mt-5 mb-3">
                            <button className='btn btn-sm btn-primary' onClick={handleSearch}>Search</button>
                        </div>
                    </div> */}

                    <div className=" p-2 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary mt-4 mb-2 mr-1"
                            // style={{ marginRight: '10px' }}
                            onClick={handleSearch}
                        >
                            Search
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-secondary mt-4 mb-2"
                            onClick={resetData}
                        >Reset
                        </button>
                    </div>


                </div>

                
                        <div style={{margin: '0% 5% 0% 0%', float: 'right'}}>
                            {packingView.length > 0 && <div className="text-end mb-3">
                                <button
                                    type="button"
                                    onClick={handleDownloadPDF}
                                    className="m-1 btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> PDF
                                </button>
                                <CSVLink
                                    data={csvData}
                                    filename="packing_galley.csv"
                                    className="btn btn-sm btn-primary"
                                >
                                    <Feather.Download /> CSV
                                </CSVLink>
                            </div>
                            }
                        </div>
                   

                <div className="container-fluid m-3" style={{ width: '150%' }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Code</th>
                                <th>Exam</th>
                                <th>Date</th>
                                <th>Session</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Discipline Course</th>
                                <th>Question Paper Code</th>
                                <th>Seq No</th>
                                <th>Present List</th>
                                <th>Absent List</th>
                                <th>Malpractice List</th>
                                <th>Special Case List</th>
                                <th>Special Packing List</th>
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {packingView.map((row, index) => (
                                <tr key={row.id}>
                                    <td>{index + 1}</td>
                                    <td>{row.code}</td>
                                    <td>{getExamName(row.examId)}</td>
                                    <td>{formatDate(row.date)}</td>
                                    <td>{row.session}</td>
                                    <td>{getDegreeName(row.degreeId)}</td>
                                    <td>{getDisciplineName(row.disciplineId)}</td>
                                    <td>{getCourseName(row.disciplineCourseId)}</td>
                                    <td>{row.questionPaperCode}</td>
                                    <td>{row.seqNo}</td>
                                    <td>{row.presentList}</td>
                                    <td>{row.absentList}</td>
                                    <td>{row.malpracticeList}</td>
                                    <td>{row.specialCaseList}</td>
                                    <td>{row.specialPackingList}</td>
                                    <td>
                                        <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => confirmDeleteHandler(row.disciplineId || '')}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
};

export default Packing;


interface Discipline {
    id: number;
    name: string;
}

interface Course {
    id: number;
    name: string;
}

interface Degree {
    id: number;
    name: string;
}

interface DisciplineCourse {
    id: number;
    degreeId: string;
    disciplineId: string;
    courseId: string;
}

interface Exam {
    id: number;
    title: string;
}

interface ExamAttendance {
    id: number;
    examId: string;
    degreeId: string;
    disciplineId: string;
    batch: string;
    registerNo: string;
    date: string;
    session: string;
    disciplineCourseId: string;
    answerBookNo: string;
    attendance: string;
}