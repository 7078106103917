import axios from 'axios';
import { FC, useEffect, useState } from 'react';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
// import './styles/tableReport.css';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { apiUrl } from '../config';
import { CourseType } from '../redux/actions/courseActions';
import { DegreeType } from '../redux/actions/degreeAction';
import { DisciplineType } from '../redux/actions/disciplineAction';
import { StoreState } from '../redux/reducers';
import imgPath from './img/collegeLogo.png';


interface Props {
    title: string;
}

const AssessmentReport: FC<Props> = (props: Props) => {

    const { title } = props;
    const fileName = 'student_assessment_report';

    const [academicYearFilter, setAcademicYearFilter] = useState('');
    const [academicYearOptions, setAcademicYearOptions] = useState<AcademicYearOption[] | never[]>([]);
    const [semester, setSemester] = useState('');
    const [disciplineFilter, setDisciplineFilter] = useState('');

    const selectedDiscipline = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const [selectedDisciplineId, setSelectedDisciplineId] = useState<string>('');
    const [disciplineIdError, setDisciplineIdError] = useState('');

    const selectedDegree = useSelector<StoreState, DegreeType[]>(state => state.degree);
    const [selectedDegreeId, setSelectedDegreeId] = useState('');
    const [degreeIdError, setDegreeIdError] = useState('');


    // const selectedPhase = useSelector<StoreState, PhaseType[]>(state => state.phase);
    // const [selectedPhaseId, setSelectedPhaseId] = useState('');
    // const [phaseIdError, setPhaseIdError] = useState('');

    const [studentList, setStudentList] = useState<StuAssessment[]>([]);

    const courseList = useSelector<StoreState, CourseType[]>(state => state.course);

    const [last10Years, setLast10Years] = useState<number[]>([]);

    const [searchPerformed, setSearchPerformed] = useState(false);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);

    useEffect(() => {
        const generateAcademicYearOptions = () => {
            const currentYear = new Date().getFullYear();
            const previousYear = currentYear - 1;
            const nextYear = currentYear + 1;

            const options: AcademicYearOption[] = [
                { value: '', label: 'Select Academic Year' },
                { value: `${currentYear} - ${nextYear}`, label: `${currentYear} - ${nextYear}` },
                { value: `${previousYear} - ${currentYear}`, label: `${previousYear} - ${currentYear}` },
            ];

            setAcademicYearOptions(options as AcademicYearOption[]);
        };

        generateAcademicYearOptions();
    }, []);

    const filterStudent = () => {
        if (
            selectedDegreeId !== '' &&
            selectedDisciplineId !== '' &&
            selectedPhaseId !== '' &&
            selectedBatchId !== '' &&
            semester !== '' &&
            academicYearFilter !== ''
        ) {
            axios.get<{ data: StuAssessment[] }>(`${apiUrl}/studentAssessment/?degreeId=${selectedDegreeId}&disciplineId=${selectedDisciplineId}&phaseId=${selectedPhaseId}&batch=${selectedBatchId}&semester=${semester}`)
                .then((response) => {
                    setStudentList(response.data.data);
                    console.log(response.data.data);
                })
                .catch((error) => console.log(error));
            setSearchPerformed(true);
        } else {
            toast.error('Please select all filters');
        }
    };

    const renderDownloadButtons = () => {
        if (searchPerformed) {
            return (
                <div className="text-end mb-3">
                    <button
                        type="button"
                        onClick={handleDownloadPDF}
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> PDF
                    </button>
                    <CSVLink
                        data={csvData}
                        filename="details_of_course.csv"
                        className="m-1 btn btn-sm btn-primary"
                    >
                        <Feather.Download /> CSV
                    </CSVLink>
                </div>
            );
        }
        return null;
    };


    // //degree filter 
    // const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    // const [selectedDegreeId, setSelectedDegreeId] = useState('');
    // const [degreeIdError, setDegreeIdError] = useState('');

    // useEffect(() => {
    //     axios.get(`${apiUrl}/degree/`)
    //         .then((response) => {
    //             setSelectedDegree(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    // //discipline filter
    // const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    // const [selectedDisciplineId, setSelectedDisciplineId] = useState('');
    // const [disciplineIdError, setDisciplineIdError] = useState('');

    // useEffect(() => {
    //     axios.get(`${apiUrl}/discipline/`)
    //         .then((response) => {
    //             setSelectedDiscipline(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching discipline course', error);
    //         });
    // }, [])

    //phase filter 
    const [selectedPhase, setSelectedPhase] = useState<Phase[]>([]);
    const [selectedPhaseId, setSelectedPhaseId] = useState('');
    const [phaseIdError, setPhaseIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/phase/`)
            .then((response) => {
                setSelectedPhase(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching phase', error);
            });
    }, []);

    //batch filter
    const [selectedBatch, setSelectedBatch] = useState<Student[]>([]);
    const [selectedBatchId, setSelectedBatchId] = useState('');
    const [batchIdError, setBatchIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/student/`)
            .then((response) => {
                setSelectedBatch(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching student', error);
            });
    }, []);

    // //course short_name
    // useEffect(() => {
    //     axios.get(`${apiUrl}/course/`)
    //         .then((response) => {
    //             setCourseList(response.data.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching course', error);
    //         });
    // }, []);



    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(16);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text(title, 110, 53, { align: 'center' });

        const unwantedKeys = ["register_no", "department_register_number", "name"];

        const headers = Object.keys(studentList[0]!)
            .filter(key => !unwantedKeys.includes(key))
            .map((key, index) => {
                const matchingCourse = courseList.find(course => course.name === key);
                console.log(courseList);
                if (matchingCourse) {
                    return { content: `${matchingCourse.shortName}`, colSpan: 1 };
                }
                return { content: `C${index + 1}`, colSpan: 1 };
            });



        const getPhaseId = (phaseId: string) => {
            const course = selectedPhase.find((e) => e.id === parseInt(phaseId));
            return course ? course.title : 'CONSOLIDATED MARKS';
        };

        const getDisciplineId = (phaseId: string) => {
            const course = selectedDiscipline.find((e) => e.id === parseInt(phaseId));
            return course ? course.name : 'N/A';
        };

        const totalColSpan = headers.reduce((acc, header) => acc + header.colSpan, 0);

        autoTable(doc, {
            startY: 65,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 7, valign: 'middle', halign: 'center' },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 7, halign: 'center' },
            tableWidth: 200,
            margin: { left: 5, right: 5 },
            head: [
                [
                    { content: `AY: ${academicYearFilter} `, colSpan: 2, styles: { halign: "center" } },
                    { content: `SEM.: ${semester} `, styles: { halign: "center" } },
                    { content: `DISCIPLINE: ` + getDisciplineId(selectedDisciplineId), colSpan: 1, styles: { halign: "center" } },
                    { content: getPhaseId(selectedPhaseId), colSpan: totalColSpan, styles: { halign: "center" } },
                ],
                [
                    { content: "S.No.", styles: { halign: "center" } },
                    { content: "Reg.No.", styles: { halign: "center" } },
                    { content: "D.No.", styles: { halign: "center" } },
                    { content: "Name of the student ", colSpan: 1, styles: { halign: "center", cellWidth: 5 } },
                    ...headers,
                ],
            ],
            body: studentList.map((row, index) => {
                const cellContents = Object.keys(row).map(key => (
                    !unwantedKeys.includes(key) && { content: row[key] !== null ? row[key].toString() : "-" }
                )).filter(Boolean);

                return [
                    { content: index + 1, styles: { halign: 'center' } },
                    { content: row["register_no"], styles: { halign: 'center' } },
                    { content: row["department_register_number"], styles: { halign: 'center' } },
                    { content: row["name"], styles: { halign: 'left' } },
                    ...cellContents,
                ];
            }),
            theme: 'grid',
            columnStyles: {
                0: { cellWidth: 10 },
                1: { cellWidth: 22 },
                2: { cellWidth: 11 },
                3: { cellWidth: 33 },
            },
        });

        autoTable(doc, {
            didDrawPage: (data) => {
                doc.setFontSize(9);
                doc.setFont('Arial', 'normal', 'bold');
                doc.setTextColor(0, 0, 0);
                let yPos = (doc as any).lastAutoTable.finalY + 10;
                const displayedKeys: any = [];
                let displayedKeysCount = 0;

                const columns: { header: string, dataKey: string }[] = [];


                const headers = Object.keys(studentList[0])
                    .filter(key => !unwantedKeys.includes(key))
                    .map((key, index) => {
                        if (key !== "register_no" && key !== "department_register_number" && key !== "name" && !displayedKeys.includes(key)) {
                            const matchingCourse = courseList.find(course => course.name === key);

                            if (matchingCourse) {
                                columns.push({ header: `${matchingCourse.shortName} - ${key}`, dataKey: key });
                                displayedKeys.push(key);
                                displayedKeysCount++;
                            }
                        }
                    });


                autoTable(doc, {
                    startY: yPos,
                    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8, },
                    bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
                    head: columns.map(col => [col.header]),
                    // body: rows,
                    theme: 'plain',
                });
            }
        });

        doc.save(fileName + '.pdf');
    }


    const getUniqueBatch = () => {
        const uniqueBatch = Array.from(new Set(selectedBatch.map((batch) => batch.batch)));
        return uniqueBatch;
    };

    const unwantedKeys = ["register_no", "department_register_number", "name"];

    const headers = studentList.length > 0
        ? Object.keys(studentList[0])
            .filter(key => !unwantedKeys.includes(key))
            .map((key, index) => {
                const matchingCourse = courseList.find(course => course.name === key);
                console.log(courseList);
                if (matchingCourse) {
                    return `${matchingCourse.shortName}`;
                }
                return `C${index + 1}`;
            })
        : [];


    const csvData = studentList.map((row, index) => {
        const cellContents = Object.keys(row)
            .filter((key) => !unwantedKeys.includes(key))
            .map((key) => (row[key] !== null ? row[key].toString() : "-"));

        // Create a row for each student
        const rowData = [
            index + 1,
            row["register_no"],
            row["department_register_number"],
            row["name"],
            ...cellContents,
        ];

        return rowData;
    });

    // Add header row to csvData
    const headerRow = [
        'S.No', 'Reg.No', 'D.No.', 'Name of the student',
        ...headers,
    ];

    csvData.unshift(headerRow);

    return (
        <>
            <PageContainer title="Details Of Assessment">
                <Card title="filters">
                    <div className="row">

                        <div className="col-4">
                            <label className="mt-3 mb-3">Academic Year <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="academicYearFilter"
                                value={academicYearFilter}
                                onChange={(e) => setAcademicYearFilter(e.target.value)}
                            >
                                {academicYearOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Degree <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedDegree"
                                value={selectedDegreeId}
                                onChange={(e) => setSelectedDegreeId(e.target.value)}
                            >
                                <option value="" selected>Select Degree</option>
                                {selectedDegree.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Discipline <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="selectedDiscipline"
                                value={selectedDisciplineId}
                                onChange={(e) => setSelectedDisciplineId(e.target.value)}
                            >
                                <option value="" selected>Select Discipline</option>
                                {selectedDiscipline.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Phase <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedPhase"
                                value={selectedPhaseId}
                                onChange={(e) => setSelectedPhaseId(e.target.value)}
                            >
                                <option value="" selected>Select Phase</option>
                                <option value="0"> Consolidate</option>
                                {selectedPhase.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                            {phaseIdError && <div className="text-danger">{phaseIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Batch <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedBatch"
                                value={selectedBatchId}
                                onChange={(e) => setSelectedBatchId(e.target.value)}
                            >
                                <option value="" selected>Select Batch</option>
                                {last10Years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                            {batchIdError && <div className="text-danger">{batchIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Semester <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedBatch"
                                value={semester}
                                onChange={(e) => setSemester(e.target.value)}
                            >
                                <option value="" selected>Select Semester</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                            </select>
                        </div>

                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={filterStudent}
                                style={{ width: "10%", marginTop: "55px", float: "right" }}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </Card>
                <CollapseCard title="Student Assessment">
                    <div className="container-fluid " style={{ width: "180%" }}>
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Register</th>
                                    <th>Discipline</th>
                                    <th>Name Of the Student</th>
                                    {headers.map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {studentList.map((student, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{student.register_no}</td>
                                        <td>{student.department_register_number}</td>
                                        <td>{student.name}</td>
                                        {Object.keys(student).map(key => (
                                            key !== "register_no" &&
                                            key !== "department_register_number" &&
                                            key !== "name" && (
                                                <td key={key}>{student[key] !== null ? student[key] : '-'}</td>
                                            )
                                        ))}
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>

                    <div>
                        {renderDownloadButtons()}
                    </div>
                </CollapseCard>
            </PageContainer>
        </>
    );
}

export default AssessmentReport;

interface StuAssessment {
    // id: number;
    register_no: string;
    department_register_number: string;
    name: string;
    mark: string;
    [key: string]: string;
}

interface Student {
    id: number;
    registerNo: string;
    batch: string;
}

interface Discipline {
    id: number;
    name: string;
}

interface Degree {
    id: number;
    name: string;
}

interface Phase {
    id: number;
    title: string;
}

interface Course {
    id: number;
    name: string;
    shortName: string;
}

interface AcademicYearOption {
    value: string;
    label: string;
}