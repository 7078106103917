import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import Card from "../components/custom/Card"
import PageContainer from "../components/custom/PageContainer"
import { apiUrl } from "../config"
import { CourseType } from "../redux/actions/courseActions"
import { DegreeType } from "../redux/actions/degreeAction"
import { DisciplineType } from "../redux/actions/disciplineAction"
import { DisciplineCourseType } from "../redux/actions/disciplineCourseAction"
import { ExamType } from "../redux/actions/examAction"
import { fetchStudentMarkAction } from "../redux/actions/studentMarkAction"
import { StoreState } from "../redux/reducers"
import { AppDispatch } from "../redux/store"

interface FilteredStudentMarkType {
    id: number
    name: string
    regNo: string
    deptRegNo: string
    dummyRegNo: string
    grade: string
    gradeText: string
    unvGrade: string
    unvGradeText: string
}

interface universityGradeAndText {
    id: number
    unvGrade: string
    unvGradeText: string
}
const UniversityGrades = () => {

    const dispatch = useDispatch<AppDispatch>()
    const examList = useSelector<StoreState, ExamType[]>(state => state.exam)
    const courseList = useSelector<StoreState, CourseType[]>(state => state.course)
    const degreeList = useSelector<StoreState, DegreeType[]>(state => state.degree)
    const disciplineList = useSelector<StoreState, DisciplineType[]>(state => state.discipline)
    const disciplineCourseList = useSelector<StoreState, DisciplineCourseType[]>(state => state.disciplineCourse)

    // const studentMarkList = useSelector<StoreState, StudentMarkType[]>(state => state.studentMark)
    // const facultyList = useSelector<StoreState, FacultyType[]>(state => state.faculty);
    // const examFacultyList = useSelector<StoreState, ExamFacultyType[]>(state => state.examFaculty);

    const [filteredStudentMarkData, setFilteredStudentMarkData] = useState<FilteredStudentMarkType[]>([])
    const [studentarrearList, setStudentarrearList] = useState<StudentArrearType[]>([]);

    const [exam, setExam] = useState<string>('')
    const [examError, setExamError] = useState<string>('')

    const [degree, setDegree] = useState<string>('')
    const [degreeError, setDegreeError] = useState<string>('')

    const [discipline, setDiscipline] = useState<string>('')
    const [disciplineError, setDisciplineError] = useState<string>('')

    const [semester, setSemester] = useState<string>('')
    const [semesterError, setSemesterError] = useState<string>('')

    const [disciplineCourse, setDisciplineCourse] = useState<string>('')
    const [disciplineCourseError, setDisciplineCourseError] = useState<string>('')

    const [securedMark, setSecuredMark] = useState<string>('')
    const [gradeError, setGradeError] = useState<string>('')

    // const [adjuestedMark, setAdjustedMark] = useState<string>('')
    // const [adjuestedMarkError, setAdjustedMarkError] = useState<string>('')

    // const [selectDummyNoList, setSelectedDummyNoList] = useState([]);

    const [last10Years, setLast10Years] = useState<number[]>([]);
    //array input update
    const [securedGrades, setSecuredGrades] = useState<universityGradeAndText[]>([]);

    //batch filter
    const [batch, setBatch] = useState('');
    const [batchError, setBatchError] = useState('');

    useEffect(() => {
        // Populate initial secured grades 
        setSecuredGrades(filteredStudentMarkData.map(data => ({
            id: data.id,
            unvGrade: data.unvGrade,
            unvGradeText: calculateUniversityGradeText(parseInt(data.unvGrade))
        })));
    }, [filteredStudentMarkData]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);


    useEffect(() => {
        dispatch(fetchStudentMarkAction())
    }, [])


    useEffect(() => {
        fetchStudentArrear()
    }, [])


    const fetchStudentArrear = () => {
        axios.get(`${apiUrl}/studentarrear/`)
            .then((response) => {
                setStudentarrearList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching student arrear', error);
            });
    }

    const resetStates = () => {
        setExam('')
        setExamError('')
        setDegree('')
        setDegreeError('')
        setDiscipline('')
        setDisciplineError('')
        setBatch('')
        setSemester('')
        setSemesterError('')
        // setRegisterNo('')
        // setRegisterNoError('')
        setDisciplineCourse('')
        setDisciplineCourseError('')
        setSecuredMark('')
        setGradeError('')
        // setAdjustedMark('')
        // setAdjustedMarkError('')
        // setIntExaminer('')
        // setIntExaminerError('')
        // setExtExaminer('')
        // setExtExaminerError('')
        // setEditFlag(false)
        // setSelectedDummyNoList([]);
        setSecuredGrades([]);
        setFilteredStudentMarkData([])
    }

    const calculateGrade = (totalConversion: number) => {
        if (totalConversion >= 91 && totalConversion <= 100) {
            return 10;
        } else if (totalConversion >= 81 && totalConversion <= 90) {
            return 9;
        } else if (totalConversion >= 71 && totalConversion <= 80) {
            return 8;
        } else if (totalConversion >= 61 && totalConversion <= 70) {
            return 7;
        } else if (totalConversion >= 56 && totalConversion <= 60) {
            return 6;
        } else if (totalConversion >= 50 && totalConversion <= 55) {
            return 5;
        } else {
            return 0;
        }
    };

    const calculateGradeText = (totalConversion: number) => {
        if (totalConversion >= 91 && totalConversion <= 100) {
            return 'O';
        } else if (totalConversion >= 81 && totalConversion <= 90) {
            return 'A+';
        } else if (totalConversion >= 71 && totalConversion <= 80) {
            return 'A';
        } else if (totalConversion >= 61 && totalConversion <= 70) {
            return 'B+';
        } else if (totalConversion >= 56 && totalConversion <= 60) {
            return 'B';
        } else if (totalConversion >= 50 && totalConversion <= 55) {
            return 'C';
        } else {
            return 'U';
        }
    };

    const calculateUniversityGradeText = (unvgrade: number) => {
        if (unvgrade === 10) {
            return 'O';
        } else if (unvgrade === 9) {
            return 'A+';
        } else if (unvgrade === 8) {
            return 'A';
        } else if (unvgrade === 7) {
            return 'B+';
        } else if (unvgrade === 6) {
            return 'B';
        } else if (unvgrade === 5) {
            return 'C';
        } else if (unvgrade === 0) {
            return 'U';
        } else return ''
    };

    //university grade text input 
    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const enteredValue = e.target.value;
        const isValid = enteredValue !== '';

        if (isValid) {
            const newSecuredGrades = [...securedGrades];
            newSecuredGrades[index].unvGrade = enteredValue;
            newSecuredGrades[index].unvGradeText = calculateUniversityGradeText(parseInt(enteredValue));
            setSecuredGrades(newSecuredGrades);
            setGradeError('');
        } else {
            setGradeError('Grade must be within -1 to 100');
        }
    };

    const handleArrear = async (dataItem: any, semester: number) => {

        const studentArrearData = {
            disciplineId: dataItem.disciplineId,
            degreeId: dataItem.degreeId,
            registerNo: dataItem.registerNumber,
            semesterNo: semester,
            disciplineCourseId: dataItem.disciplineCourseId,
            status: 'Pending',
        };

        await axios.post(`${apiUrl}/studentarrear/`, studentArrearData).then((response) => {
            // console.log(response.data.data);
            if (response.status === 200 && response.data.data) {
                toast.success(response.data.message);
            }
        }).catch((error) => {
            if (error!.response) {
                toast.error(error.response.data.message);
            }
        });


    };

    const handleDeleteArrear = async (dataItem: any, semester: number) => {

        console.log(dataItem)

        let deleteArrearIfTheStudentPass = studentarrearList.find(sa =>
            sa.disciplineId == dataItem.disciplineId &&
            sa.degreeId == dataItem.degreeId &&
            sa.disciplineCourseId == dataItem.disciplineCourseId &&
            sa.registerNo == dataItem.registerNumber &&
            sa.semesterNo == semester
        )?.id;

        console.log(deleteArrearIfTheStudentPass)
        if (deleteArrearIfTheStudentPass !== undefined || "" || 0) {
            await axios.delete(`${apiUrl}/studentarrear/?id=${deleteArrearIfTheStudentPass}`).then((response) => {
                // console.log(response.data.data);
                if (response.status === 200 && response.data.data) {
                    toast.success(response.data.message);
                }
            }).catch((error) => {
                if (error!.response) {
                    toast.error(error.response.data.message);
                }
            });
        }
    };

    const saveHandler = async () => {
        let error = false;

        if (securedGrades.length === 0) {
            error = true;
            setGradeError('Data required');
        }

        securedGrades?.forEach((d, i) => {
            // console.log(securedGrades[i]);

            if (parseInt(securedGrades[i].unvGrade) < 0 || parseInt(securedGrades[i].unvGrade) > 10) {
                error = true;
                toast.error(`Invalid Grade given for record ${i + 1}`);
            } else if (securedGrades[i].unvGradeText === '') {
                error = true;
                toast.error(`Grade Required for record ${i + 1}`);
            }
        });

        let disId = disciplineCourseList.find((dc => dc.degreeId === parseInt(degree) && dc.disciplineId === parseInt(discipline) && dc.courseId === parseInt(disciplineCourse)))?.id
        const editData = filteredStudentMarkData?.map((sm, index) => {

            return {
                id: sm.id,
                degreeId: degree,
                disciplineId: discipline,
                disciplineCourseId: disId,
                semester: semester,
                registerNumber: sm.regNo,
                unvGrade: securedGrades[index]?.unvGrade,
                unvGradeText: securedGrades[index]?.unvGradeText
            };

        })

        if (!error) {
            axios.put(`${apiUrl}/studentMark/universityGradeUpdate/`, editData).then((response) => {
                // console.log(response.data.data);
                if (response.status === 200 && response.data.data) {
                    toast.success(response.data.message);
                    dispatch(fetchStudentMarkAction());
                    fetchStudentArrear()
                    resetStates();
                }
            }).catch((error) => {
                if (error!.response) {
                    // console.log(error.response.data);
                    toast.error(error.response.data.message);
                }
            });
        }

        let getSemesterForCheck = studentarrearList.find(sa =>
            sa.disciplineId === parseInt(discipline) &&
            sa.degreeId === parseInt(degree) &&
            sa.disciplineCourseId === disId &&
            sa.registerNo === editData.find((fs) => fs.registerNumber === sa.registerNo)?.registerNumber &&
            sa.semesterNo === parseInt(semester)
        )?.semesterNo || 0;

        let getSemesterFromDisCourse = disciplineCourseList.find(dc => dc.id === parseInt(disciplineCourse))?.semesterNo

        editData.forEach(data => {
            if (parseInt(data.unvGrade) === 0 && data.unvGradeText === 'U' && getSemesterFromDisCourse === parseInt(semester)) {
                handleArrear(data, parseInt(semester));
            } else if (data.unvGrade && parseInt(data.unvGrade) > 0 && data.unvGradeText !== 'U' && getSemesterForCheck === parseInt(semester)) {
                handleDeleteArrear(data, parseInt(semester));
            }
        });
    };

    const setValues = (data: FilteredStudentMarkType[]) => {
        let newArr: universityGradeAndText[] = []
        data.forEach(d => {
            newArr.push({
                id: d.id,
                unvGrade: d.unvGrade,
                unvGradeText: d.unvGradeText !== '' ? d.unvGradeText : calculateUniversityGradeText(parseInt(d.unvGrade))
            })
        })
        setSecuredGrades(newArr)
    }

    const filterStudent = () => {
        if (
            degree !== '' &&
            discipline !== '' &&
            exam !== '' &&
            disciplineCourse !== '' &&
            semester !== '' &&
            batch !== ''
        ) {
            // console.log(degree);
            // console.log(discipline);
            // console.log(disciplineCourse);
            // console.log(disciplineCourseList);

            let disCourseId = disciplineCourseList?.find(f => f.degreeId?.toString() === degree && f.disciplineId?.toString() === discipline && f.courseId?.toString() === disciplineCourse)?.id

            if (disCourseId) {
                axios.get<{ data: FilteredStudentMarkType[] }>(`${apiUrl}/studentMark/?degreeId=${degree}&disciplineId=${discipline}&batch=${batch}&disciplineCourseId=${disCourseId}&semester=${semester}&examId=${exam}`)
                    .then((response) => {
                        const result = response.data.data
                        if (result.length > 0) {
                            setSecuredGrades([])
                            setFilteredStudentMarkData(result)
                            setValues(result)
                            // console.log(filteredStudentMarkData)
                        } else {
                            setFilteredStudentMarkData([])
                        }
                    })
                    .catch((error) => {
                        toast.error(error);
                        console.error('Error fetching students', error);
                    });
            }
        } else {
            toast.error('Please select all filters');
        }
    };

    return (
        <PageContainer title="University Grade">
            <Card title={"Edit - University Grades"}>
                <div className="row">
                    <div className="col-4">
                        <label htmlFor="" className="mt-3 mb-3">Exam <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={exam} onChange={(e) => {
                            setExam(e.target.value)
                            setExamError('')
                        }}>
                            <option value="" selected>Select Exam</option>
                            {examList?.map((ex) => (
                                <option key={ex.id} value={ex.id}>
                                    {ex.title}
                                </option>
                            ))}
                        </select>
                        {examError && <div className="text-danger">{examError}</div>}
                    </div>

                    <div className="col-4" >
                        <label htmlFor="" className="mt-3 mb-3">Degree <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={degree} onChange={(e) => {
                            setDegree(e.target.value)
                            setDegreeError('')
                        }}>
                            <option value="" selected>Select Degree</option>
                            {degreeList?.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                        {degreeError && <div className="text-danger">{degreeError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Discipline <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control" value={discipline} onChange={(e) => {
                            setDiscipline(e.target.value)
                            setDisciplineError('')
                        }}>
                            <option value="" selected>Select Discipline</option>
                            {disciplineList?.map((disc) => (
                                <option key={disc.id} value={disc.id}>
                                    {disc.name}
                                </option>
                            ))}
                        </select>
                        {disciplineError && <div className="text-danger">{disciplineError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Semester <span className="text-danger">*</span> : </label>
                        <select name="" id="" className="form-control"
                            value={semester}
                            onChange={(e) => {
                                setSemester(e.target.value)
                                setSemesterError('')
                            }}>
                            <option value="" selected>Select Semester</option>
                            <option value="1" selected>1</option>
                            <option value="2" selected>2</option>
                            <option value="3" selected>3</option>
                            <option value="4" selected>4</option>
                            <option value="5" selected>5</option>
                            <option value="6" selected>6</option>
                            <option value="7" selected>7</option>
                            <option value="8" selected>8</option>
                        </select>
                        {semesterError && <div className="text-danger">{semesterError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Batch <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedBatch"
                            value={batch}
                            onChange={(e) => setBatch(e.target.value)}
                        >
                            <option value="" selected>Select Batch</option>
                            {last10Years?.map((year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {batchError && <div className="text-danger">{batchError}</div>}
                    </div>

                    <div className="col-4">
                        <label className="mt-3 mb-3">Course <span className="text-danger">*</span> : </label>
                        <select
                            className="form-control"
                            value={disciplineCourse}
                            onChange={(e) => {
                                setDisciplineCourse(e.target.value);
                                setDisciplineCourseError('');
                            }}
                        >
                            <option value="">Select Course</option>
                            {courseList?.map((course) => {

                                const filteredCourses = disciplineCourseList.filter(
                                    (dc) => dc.degreeId == parseInt(degree) && dc.disciplineId == parseInt(discipline) && dc.courseId === course.id && (dc.courseType === 'Theory' || dc.courseType === 'Theory cum practical')
                                );
                                return filteredCourses.map((filteredCourse) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ));
                            })}
                        </select>
                        {disciplineCourseError && <div className="text-danger">{disciplineCourseError}</div>}
                    </div>



                    <div className="col-12">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            onClick={filterStudent}
                            style={{ width: "10%", marginTop: "30px", float: "right" }}
                        >
                            Search
                        </button>
                    </div>

                    <div className="container-fluid" style={{ marginTop: "30px" }}>
                        <table className="table table-success table-striped">
                            <thead>
                                <tr>
                                    <th>S.No.</th>
                                    <th>Register Number</th>
                                    <th>University Grade</th>
                                    <th>University Grade Text</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredStudentMarkData?.map((sm, i) => (
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{sm.regNo}</td>
                                        <td>
                                            <select
                                                className="form-control"
                                                style={{ width: '30%' }}
                                                value={securedGrades[i]?.unvGrade}
                                                onChange={(e) => handleInputChange(e, i)}
                                            >
                                                <option value="" disabled>Select Grade</option>
                                                <option value="0">0</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                            </select>
                                            {gradeError && <div className="text-danger">{gradeError}</div>}
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ width: '30%' }}
                                                value={securedGrades[i]?.unvGradeText}
                                                readOnly
                                            />
                                        </td>
                                        {/* <td>
                                                <select
                                                    name=""
                                                    id=""
                                                    className="form-control"
                                                    value={intExaminers[i]}
                                                    onChange={(e) => {
                                                        const newIntExaminers = [...intExaminers];
                                                        newIntExaminers[i] = e.target.value;
                                                        setIntExaminers(newIntExaminers);
                                                        setIntExaminerError('');
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        Select Internal Examiner
                                                    </option>
                                                    {facultyList.map((course) => (
                                                        <option key={course.id} value={course.id}>
                                                            {course.code} - {course.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td>
                                            <td>
                                                <select
                                                    name=""
                                                    id=""
                                                    className="form-control"
                                                    value={extExaminers[i]}
                                                    onChange={(e) => {
                                                        const newExtExaminers = [...extExaminers];
                                                        newExtExaminers[i] = e.target.value;
                                                        setExtExaminers(newExtExaminers);
                                                        setExtExaminerError('');
                                                    }}
                                                >
                                                    <option value="" selected>
                                                        Select External Examiner
                                                    </option>
                                                    {facultyList.map((course) => (
                                                        <option key={course.id} value={course.id}>
                                                            {course.code} - {course.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </td> */}
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>

                    {filteredStudentMarkData.length > 0 && <div className="col-12 mt-5 my-3 py-2 hstack">
                        <button className="btn btn-sm btn-primary" style={{ float: "right" }} onClick={saveHandler}>Update</button>
                        <button className="btn btn-sm btn-secondary" style={{ marginRight: '10px', float: "right" }} onClick={resetStates}>Clear</button>
                    </div>}
                </div>
            </Card>
        </PageContainer>
    );
}

export default UniversityGrades;

interface StudentArrearType {
    id?: number;
    disciplineId: number;
    degreeId: number;
    registerNo: string;
    semesterNo: number;
    disciplineCourseId: number;
    attemptCount: number;
    clearedSemester: number;
    status: string;
    createdBy: string;
    createTimestamp: string;
}