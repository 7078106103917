import axios from 'axios';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { CourseType } from '../redux/actions/courseActions';
import { DisciplineCourseType } from '../redux/actions/disciplineCourseAction';
import Course from './Course';
import Degree from './Degree';
import Discipline from './Discipline';
import Phase from './Phase';
import { StudentType } from './Student';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import * as Feather from 'react-feather';
import imgPath from './img/collegeLogo.png';



const StuAssessment = () => {
    const [stuAssessmentList, setStuAssessmentList] = useState<StuAssessmentType[]>([]);
    const fileName = 'student_assessment';



    const [editFlag, setEditFlag] = useState(false);

    const [id, setId] = useState<number>(0);
    const [createdBy, setCreatedBy] = useState<string>('');
    const [createTimestamp, setCreateTimestamp] = useState<string>('');
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [registerNoError, setRegisterNoError] = useState('');
    const [disciplineCourseIdError, setDisciplineCourseIdError] = useState('');
    const [markError, setMarkError] = useState('');

    const [selectCourseId, setSelectCourseId] = useState<CourseType[]>([]);

    const [selectedRegisterNo, setSelectedRegisterNo] = useState<StudentType[]>([]);
    const [selectedStudent, setSelectedStudent] = useState<StudentType | null>(null);

    const [stuAssessmentTable, setStuAssessmentTable] = useState<StuAssessmentType[]>([])
    const [markArray, setMarkArray] = useState<string[]>([]);

    const [courseList, setCourseList] = useState<DisciplineCourseType[]>([]);

    const [contactHours, setContactHours] = useState<string>('');
    const [attendenceError, setAttendenceError] = useState('');
    const [contactHoursError, setContactHoursError] = useState<string>('');

    const [last10Years, setLast10Years] = useState<number[]>([]);

    //Array insert for attended hours and contact hours
    const [attendenceArray, setAttendenceArray] = useState<string[]>([]);
    const [contactHoursArray, setContactHoursArray] = useState<string[]>([]);

    //for edit Model
    const [editModelShow, setEditModelShow] = useState(false);
    const [editId, setEditId] = useState(0);
    const [editRegisterNo, setEditRegisterNo] = useState('');
    const [editDegreeId, setEditDegreeId] = useState('');
    const [editDisciplineId, setEditDisciplineId] = useState('');
    const [editCourse, setEditCourse] = useState('');
    const [editbatch, setEditBatch] = useState('');
    const [editContactHours, setEditContactHours] = useState('');
    const [editAttendence, setEditAttendence] = useState('');
    const [editMark, setEditMark] = useState('');
    const [editPhase, setEditPhase] = useState('');
    const [apiErrorMessage, setApiErrorMessage] = useState('');

    //filter view
    const [studentListFilter, setFilteredStudentList] = useState<StuAssessmentType[]>([]);



    const [selectedPhaseIdFilter, setSelectedPhaseIdFilter] = useState('');
    const [selectedCourseIdFilter, setSelectedCourseIdFilter] = useState('');
    const [degreeIdFilter, setDegreeIdFilter] = useState('');
    const [disciplineIdFilter, setDisciplineIdFilter] = useState('');
    const [batchFilter, setBatchFilter] = useState('');

    const handleMarkChange = (index: number, value: string) => {
        setMarkArray(prevArray => {
            const newArray = [...prevArray];
            newArray[index] = value;
            const parsedValue = parseFloat(value);
            if (isNaN(parsedValue) || parsedValue < -1 || parsedValue > 100) {
                setMarkError('Mark must be a number between -1 and 100');
            } else {
                setMarkError('');
            }
            return newArray;
        });
    }

    // const handelAttendenceChange = (index: number, value: string) => {
    //   setAttendenceArray(prevArray => {
    //     const newArray = [...prevArray];
    //     newArray[index] = value;
    //     return newArray;
    //   });
    // }

    const handelAttendenceChange = (index: number, value: string) => {
        setAttendenceArray((prevArray) => {
            const newArray = [...prevArray];
            newArray[index] = value;
            if (parseFloat(value) > parseFloat(contactHoursArray[index])) {
                setAttendenceError("Attended Hrs can't exceed Contact Hrs");
            } else if (parseFloat(value) < 0) {
                setAttendenceError("Invalid Attended Hrs");
            } else {
                setAttendenceError('');
            }
            return newArray;
        });
    };


    useEffect(() => {
        const initialContactHoursArray = stuAssessmentTable.map(() => contactHours);
        setContactHoursArray(initialContactHoursArray);
    }, [contactHours, stuAssessmentTable]);


    const handelContactHoursChange = (index: number, value: string) => {
        setContactHoursArray((prevArray) => {
            const newArray = [...prevArray];
            newArray[index] = value;
            return newArray;
        });
    };

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
        setLast10Years(years);
    }, []);


    //degree filter 
    const [selectedDegree, setSelectedDegree] = useState<Degree[]>([]);
    const [selectedDegreeId, setSelectedDegreeId] = useState('');
    const [degreeIdError, setDegreeIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/degree/`)
            .then((response) => {
                setSelectedDegree(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    //discipline filter
    const [selectedDiscipline, setSelectedDiscipline] = useState<Discipline[]>([]);
    const [selectedDisciplineId, setSelectedDisciplineId] = useState('');
    const [disciplineIdError, setDisciplineIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/discipline/`)
            .then((response) => {
                setSelectedDiscipline(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching discipline course', error);
            });
    }, [])

    //filter for course
    const [selectedCourse, setSelectedCourse] = useState<Course[]>([]);
    const [selectedCourseId, setSelectedCourseId] = useState('');
    const [courseIdError, setCourseIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/course/`)
            .then((response) => {
                setSelectedCourse(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching course', error);
            });
    }, []);

    //phase filter 
    const [selectedPhase, setSelectedPhase] = useState<Phase[]>([]);
    const [selectedPhaseId, setSelectedPhaseId] = useState('');
    const [phaseIdError, setPhaseIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/phase/`)
            .then((response) => {
                setSelectedPhase(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching phase', error);
            });
    }, []);

    //batch filter
    const [selectedBatch, setSelectedBatch] = useState<StudentType[]>([]);
    const [selectedBatchId, setSelectedBatchId] = useState('');
    const [batchIdError, setBatchIdError] = useState('');

    useEffect(() => {
        axios.get(`${apiUrl}/student/`)
            .then((response) => {
                setSelectedBatch(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching student', error);
            });
    }, []);

    useEffect(() => {
        axios.get(`${apiUrl}/disciplineCourse/`)
            .then((response) => {
                setCourseList(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching Discipline Course', error);
            });
    }, []);


    // const filterStudent = () => {
    //     if (
    //         selectedDegreeId !== '' &&
    //         selectedDisciplineId !== '' &&
    //         selectedCourseId !== '' &&
    //         selectedPhaseId !== '' &&
    //         selectedBatchId !== '' &&
    //         contactHours !== ''
    //     ) {
    //         axios
    //             .get<{ data: StuAssessmentType[] }>(`${apiUrl}/studentAssessment/?degreeId=${selectedDegreeId}&disciplineId=${selectedDisciplineId}&batch=${selectedBatchId}&courseId=${selectedCourseId}`)
    //             .then((response) => {
    //                 setStuAssessmentTable(response.data.data || []);
    //                 console.log(response.data.data);
    //             })
    //             .catch((error) => console.log(error));
    //     } else {
    //         toast.error('Please select all filters');
    //     }
    // };


    const filterStudent = () => {
        if (
            selectedDegreeId !== '' &&
            selectedDisciplineId !== '' &&
            selectedCourseId !== '' &&
            selectedPhaseId !== '' &&
            selectedBatchId !== '' &&
            contactHours !== ''
        ) {
            axios.get<{ error: any; message: any; data: StuAssessmentType[] }>(
                `${apiUrl}/studentAssessment/?phase=${selectedPhaseId}&degreeId=${selectedDegreeId}&disciplineId=${selectedDisciplineId}&disciplineCourseId=${selectedCourseId}&batch=${selectedBatchId}`
            )
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message);
                    } else {
                        axios.get<{ data: StuAssessmentType[] }>(
                            `${apiUrl}/studentAssessment/?degreeId=${selectedDegreeId}&disciplineId=${selectedDisciplineId}&batch=${selectedBatchId}&courseId=${selectedCourseId}`
                        ).then((response) => {
                            setStuAssessmentTable(response.data.data);
                            setMarkError('')
                            setAttendenceError('')
                            console.log(response.data.data);
                        });
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.message);
                });
        } else {
            toast.error('Please select all filters');
        }
    };

    // useEffect(() => {
    //     fetchData();
    // }, [selectedPhaseIdFilter, degreeIdFilter, disciplineIdFilter, selectedCourseIdFilter, batchFilter]);

    // disCourseId = courseList.find(f => f.id?.toString() === selectedCourseIdFilter)?.courseId;

    const getDisciplineCourseId = (selectedCourseId: number) => {
        console.log(selectedCourseId);
        return courseList.find(f => f.id === selectedCourseId)?.courseId;
    };

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        axios.get<{ data: StuAssessmentType[] }>(`${apiUrl}/studentAssessment/`)
            .then((response) => {
                setStuAssessmentList(response.data.data);
                console.log(response.data.data);

                const filteredStudents = response.data.data.filter((student) => {

                    return (
                        (selectedPhaseIdFilter === '' || student.phase.toString() === selectedPhaseIdFilter) &&
                        (degreeIdFilter === '' || student.degreeId.toString() === degreeIdFilter) &&
                        (disciplineIdFilter === '' || student.disciplineId.toString() === disciplineIdFilter) &&
                        (selectedCourseIdFilter === '' || getDisciplineCourseId(parseInt(student.disciplineCourseId)) === parseInt(selectedCourseIdFilter)) &&
                        // (selectedCourseIdFilter === '' || getDisciplineCourseId(student.disciplineCourseId).includes(parseInt(selectedCourseIdFilter))) &&
                        (batchFilter === '' || student.batch.toString() === batchFilter)
                    );
                });

                const checkFilteredStudents = selectedPhaseIdFilter !== '' || degreeIdFilter !== '' || disciplineIdFilter !== '' || selectedCourseIdFilter !== '' || batchFilter !== ''
                    ? filteredStudents
                    : response.data.data;

                setFilteredStudentList(checkFilteredStudents);
            })
            .catch((error) => console.log(error));
    };

    const resetData = () => {
        setSelectedPhaseIdFilter('')
        setDegreeIdFilter('')
        setDisciplineIdFilter('')
        setSelectedCourseIdFilter('')
        setBatchFilter('')
        fetchData();
    }


    const addHandler = () => {
        let error = false

        if (selectedCourseId.length === 0) {
            setCourseIdError('Discipline Course  is required');
            error = true;
        }
        if (selectedCourseId.length > 11) {
            setCourseIdError('Course  must be within 11 characters');
            error = true;
        }
        if (selectedPhaseId.length === 0) {
            setPhaseIdError('Phase  is required');
            error = true;
        }
        if (selectedPhaseId.length > 11) {
            setPhaseIdError('Phase  must be within 11 characters');
            error = true;
        }

        if (contactHours.length === 0) {
            setContactHoursError('Contact Hours is required');
            error = true;
        }
        if (contactHours.length > 11) {
            setContactHoursError('Contact Hours must be within 11 characters');
            error = true;
        }

        if (stuAssessmentTable.length === 0) {
            toast.error('No records to add. Please use the filters to fetch records.');
            error = true;
        }

        const hasErrors = stuAssessmentTable.some((exam, index) => {
            if (!contactHoursArray[index]?.trim()) {
                setContactHoursError(`Contact Hours is required`);
                return true;
            } else if (!attendenceArray[index]?.trim()) {
                setAttendenceError(`Attended Hours is required`);
                return true;
            } else if (!markArray[index]?.trim()) {
                setMarkError(`Mark is required`);
                return true;
            }
            return false;
        })

        const getDisciplineCourseId = (degreeId: string, disciplineId: string, courseId: string) => {
            const selectedCourseIdInt = parseInt(courseId);
            const selectedDisciplineIdInt = parseInt(disciplineId);
            const selectedDegreeIdInt = parseInt(degreeId);
            const course = selectedCourse.find((c) => c.id === selectedCourseIdInt);
            const discipline = selectedDiscipline.find((c) => c.id === selectedDisciplineIdInt);
            const degree = selectedDegree.find((c) => c.id === selectedDegreeIdInt);
            if (course && discipline && degree) {
                console.log(course);
                const foundCourse = courseList?.find((disciplineCourse) => disciplineCourse.courseId === course.id && disciplineCourse.disciplineId === discipline.id && disciplineCourse.degreeId === degree.id);
                if (foundCourse) {
                    return foundCourse.id;
                }
            }
            return 'N/A';
        };

        stuAssessmentTable.forEach((Data, index) => {
            if (markArray[index] === '') {
                toast.error(`Mark required in Line ${index + 1}`);
                error = true;
            } else if (parseInt(markArray[index]) < -1 || parseInt(markArray[index]) > 100) {
                toast.error(`Invalid Mark, Mark must be within -1 to 100 in Line ${index + 1}`);
                error = true;
            }

            if (contactHoursArray[index] === '') {
                toast.error(`Contact Hours required in Line ${index + 1}`);
                error = true;
            }

            if (attendenceArray[index] === '') {
                toast.error(`Attended Hours required in Line ${index + 1}`);
                error = true;
            } else if (parseInt(attendenceArray[index]) < 0 || parseInt(attendenceArray[index]) > parseInt(contactHoursArray[index])) {
                toast.error(`Attended.Hr must be within 0 to ${contactHoursArray[index]} in Line ${index + 1}`);
                error = true;
            }
        })

        const disciplineCoursId = getDisciplineCourseId(selectedDegreeId, selectedDisciplineId, selectedCourseId);

        const newAssessments = stuAssessmentTable.map((assessment, index) => ({
            registerNo: assessment.registerNo,
            degreeId: selectedDegreeId,
            disciplineId: selectedDisciplineId,
            disciplineCourseId: disciplineCoursId,
            batch: selectedBatchId,
            contactHours: contactHoursArray[index],
            attendedHours: attendenceArray[index],
            mark: markArray[index],
            phase: selectedPhaseId,
            createdBy,
            createTimestamp,
        }));

        if (!error) {
            axios.post(`${apiUrl}/studentAssessment/`, newAssessments)
                .then((response) => {
                    setDegreeIdError('');
                    setDisciplineIdError('');
                    setCourseIdError('');
                    setPhaseIdError('');
                    setBatchIdError('');
                    setContactHoursError('');
                    setAttendenceError('');
                    setMarkError('');
                    fetchData();
                    clearHandler();
                    toast.success(response.data.message);
                })
                .catch((error) => toast.error(error.response.data.message));
        }
    }

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation(); // Open the delete confirmation modal
    }

    const confirmDeleteHandler = () => {
        axios
            .delete(`${apiUrl}/studentAssessment/?id=${id}`)
            .then((response) => {
                fetchData();
                closeDeleteConfirmation();
                toast.success(response.data.message);
            })
            .catch((error) => {
                console.log(error);
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    };

    const openEditModel = (id: number, registerNo: string, degreeId: string, disciplineId: string, phase: string, course: string, batch: string, contactHours: string, attendedHours: string, mark: string) => {
        setEditId(id);
        setEditRegisterNo(registerNo);
        setEditDegreeId(degreeId);
        setEditDisciplineId(disciplineId);
        setEditCourse(course);
        setEditBatch(batch);
        setEditContactHours(contactHours);
        setEditAttendence(attendedHours);
        setEditMark(mark);
        setEditPhase(phase);
        setEditModelShow(true);
    }
    

    const saveEdit = () => {
        let error = false;

        if (editMark === '') {
            setMarkError('Mark required');
            error = true;
        } else if (parseInt(editMark) < -1 || parseInt(editMark) > 100) {
            setMarkError('Invalid Mark, Mark must be within -1 to 100');
            error = true;
        }

        if (editContactHours === '') {
            setContactHoursError('Contact Hours required');
            error = true;
        }

        if (editAttendence === '') {
            setAttendenceError('Attended Hours required');
            error = true;
        } else if (parseInt(editAttendence) < 0 || parseInt(editAttendence) > parseInt(editContactHours)) {
            setAttendenceError(`Attended.Hr must be within 0 to ${editContactHours}`);
            error = true;
        }

        const getDisciplineCourseId = (courseId: string) => {
            const selectedCourseIdInt = courseId;
            const course = selectedCourse.find((c) => c.name === selectedCourseIdInt);
            if (course) {
                const foundCourse = courseList.find((disciplineCourse) => disciplineCourse.courseId === course.id);
                if (foundCourse) {
                    return foundCourse.id;
                }
            }
            return 'N/A';
        };

        

        const editData = {
            id: editId,
            registerNo: editRegisterNo,
            disciplineCourseId: getDisciplineCourseId(editCourse),
            contactHours: editContactHours,
            attendedHours: editAttendence,
            mark: editMark,
            phase: editPhase,

        }

        if (!error) {
            axios.put(`${apiUrl}/studentAssessment/?id=${editId}`, editData)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                        setApiErrorMessage(response.data.message);
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        setEditModelShow(false);
                        setMarkError('');
                        setAttendenceError('');
                        setContactHoursError('');
                        fetchData();
                    }
                })
                .catch((error) => {
                    console.error(error.response.data.message);
                });
        }

    }

    const clearHandler = () => {
        setId(0);
        setMarkError('');
        setEditFlag(false);
        setSelectedDegreeId('');
        setSelectedDisciplineId('');
        setSelectedCourseId('');
        setSelectedPhaseId('');
        setSelectedBatchId('');
        setContactHours('');
        setDegreeIdError('');
        setDisciplineIdError('');
        setCourseIdError('');
        setPhaseIdError('');
        setBatchIdError('');
        setContactHoursError('');
        setAttendenceError('');
        setMarkArray([]);
        setAttendenceArray([]);
        setContactHoursArray([]);
        setStuAssessmentTable([]);
    };


    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    }

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    }

    const getCourseId = (disciplineCourseId: StuAssessmentType) => {
        const selectedCourseIdInt = parseInt(disciplineCourseId.disciplineCourseId);
        const course = courseList?.find((c) => c.id === selectedCourseIdInt);

        if (course) {
            const foundCourse = selectedCourse.find((disciplineCourse) => disciplineCourse.id === course.courseId);
            if (foundCourse) {
                return foundCourse.name;
            }
        }

        return 'N/A';
    };


    const getPhaseId = (disciplineCourse: StuAssessmentType) => {
        const course = selectedPhase.find((disc) => disc.id === parseInt(disciplineCourse.phase));
        if (course) {
            return course.title;
        }
        return 'N/A';
    };

    const getUniqueBatch = () => {
        const uniqueBatch = Array.from(new Set(selectedBatch.map((batch) => batch.batch)));
        return uniqueBatch;
    };

    const getDegreeName = (degreeId: string) => {
        const degree = selectedDegree.find((c) => c.id == parseInt(degreeId));
        if (degree) {
            return degree.name;
        }
        return 'N/A';
    }


    const getDisciplineName = (disciplineId: string) => {
        const discipline = selectedDiscipline.find((c) => c.id == parseInt(disciplineId));
        if (discipline) {
            return discipline.name;
        }
        return 'N/A';
    }




    const handleDownloadPDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');

        doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

        doc.setFontSize(16);
        doc.setFont('Arial', 'normal', 'bold');
        doc.setTextColor(0, 0, 0);
        doc.text('STUDENT ASSESSMENT', 110, 53, { align: 'center' });

        autoTable(doc, {
            startY: 60,
            margin: 3,
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0] },
            bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0] },
            head: [
                [
                    { content: 'S.No.', styles: { halign: 'center', cellWidth: 10 } },
                    { content: 'Register No', styles: { halign: 'center', cellWidth: 25 } },
                    { content: 'Phase', styles: { halign: 'center', cellWidth: 23 } },
                    { content: 'Degree', styles: { halign: 'center', cellWidth: 20 } },
                    { content: 'Discipline', styles: { halign: 'center', cellWidth: 35 } },
                    { content: 'Discipline Course', styles: { halign: 'center', cellWidth: 25 } },
                    { content: 'Batch', styles: { halign: 'center', cellWidth: 13 } },
                    { content: 'Contact Hours', styles: { halign: 'center', cellWidth: 20 } },
                    { content: 'Attended Hours', styles: { halign: 'center', cellWidth: 20 } },
                    { content: 'Mark', styles: { halign: 'center', cellWidth: 12 } }
                ],
            ],
            body: studentListFilter.map((sl, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: sl.registerNo, styles: { halign: 'center' } },
                { content: getPhaseId(sl), styles: { halign: 'center' } },
                { content: getDegreeName(sl.degreeId), styles: { halign: 'center' } },
                { content: getDisciplineName(sl.disciplineId), styles: { halign: 'center' } },
                { content: getCourseId(sl), styles: { halign: 'center' } },
                { content: sl.batch, styles: { halign: 'center' } },
                { content: sl.contactHours, styles: { halign: 'center' } },
                { content: sl.attendedHours, styles: { halign: 'center' } },
                { content: sl.mark, styles: { halign: 'center' } },
            ]),
            theme: 'grid',
        });
        doc.save(fileName + '.pdf');
    }


    const csvData = [
        ['S.No',
            'Register No',
            'Phase',
            'Degree',
            'Discipline',
            'Discipline Course',
            'Batch',
            'Contact Hours',
            'Attended Hours',
            'Mark'
        ],

        ...studentListFilter.map((sl, i) => [
            i + 1,
            sl.registerNo,
            getPhaseId(sl),
            getDegreeName(sl.degreeId),
            getDisciplineName(sl.disciplineId),
            getCourseId(sl),
            sl.batch,
            sl.contactHours,
            sl.attendedHours,
            sl.mark

        ])

    ]




    // const handleDownloadPDF = () => {
    //     const doc = new jsPDF('p', 'mm', 'a4');

    //     doc.addImage(imgPath, 'PNG', 25, 10, 170, 40);

    //     doc.setFontSize(16);
    //     doc.setFont('Arial', 'normal', 'bold');
    //     doc.setTextColor(0, 0, 0);
    //     doc.text('DETAILS OF ASSESSMENT', 110, 53, { align: 'center' });

    //     const unwantedKeys = ["registerNo", "departmentRegisterNumber", "name"];

    //     const headers = Object.keys(stuAssessmentList[0]!)
    //         .filter(key => !unwantedKeys.includes(key))
    //         .map((key, index) => {
    //             const matchingCourse = CourseList.find(course => course.name === key);
    //             console.log(courseList);
    //             if (matchingCourse) {
    //                 return { content: `${matchingCourse.shortName}`, colSpan: 1 };
    //             }
    //             return { content: `C${index + 1}`, colSpan: 1 };
    //         });

    //     const getPhaseId = (phaseId: string) => {
    //         const course = selectedPhase.find((e) => e.id === parseInt(phaseId));
    //         return course ? course.title : 'CONSOLIDATED MARKS';
    //     };

    //     const getDisciplineId = (phaseId: string) => {
    //         const course = selectedDiscipline.find((e) => e.id === parseInt(phaseId));
    //         return course ? course.name : 'N/A';
    //     };

    //     const totalColSpan = headers.reduce((acc, header) => acc + header.colSpan, 0);

    //     autoTable(doc, {
    //         startY: 65,
    //         headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], lineWidth: 0.3, lineColor: [0, 0, 0], fontSize: 7, valign: 'middle', halign: 'center' },
    //         bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 7, halign: 'center' },
    //         tableWidth: 200,
    //         margin: { left: 5, right: 5 },
    //         head: [
    //             [
    //                 { content: `AY: ${academicYearFilter} `, colSpan: 2, styles: { halign: "center" } },
    //                 { content: `SEM.: ${semester} `, styles: { halign: "center" } },
    //                 { content: `DISCIPLINE: ` + getDisciplineId(disciplineIdFilter), colSpan: 1, styles: { halign: "center" } },
    //                 { content: getPhaseId(selectedPhaseId), colSpan: totalColSpan, styles: { halign: "center" } },
    //             ],
    //             [
    //                 { content: "S.No.", styles: { halign: "center" } },
    //                 { content: "Reg.No.", styles: { halign: "center" } },
    //                 { content: "D.No.", styles: { halign: "center" } },
    //                 { content: "Name of the student ", colSpan: 1, styles: { halign: "center", cellWidth: 5 } },
    //                 ...headers,
    //             ],
    //         ],
    //         body: studentListFilter.map((row, index) => {
    //             const cellContents = Object.keys(row).map(key => (
    //                 !unwantedKeys.includes(key) && { content: row[key] !== null ? row[key].toString() : "-" }
    //             )).filter(Boolean);

    //             return [
    //                 { content: index + 1, styles: { halign: 'center' } },
    //                 { content: row.registerNo, styles: { halign: 'center' } },
    //                 { content: row.departmentRegisterNumber, styles: { halign: 'center' } },
    //                 { content: row.name, styles: { halign: 'left' } },
    //                 ...cellContents,
    //             ];
    //         }),
    //         theme: 'grid',
    //         columnStyles: {
    //             0: { cellWidth: 10 },
    //             1: { cellWidth: 22 },
    //             2: { cellWidth: 11 },
    //             3: { cellWidth: 33 },
    //         },
    //     });

    //     autoTable(doc, {
    //         didDrawPage: (data) => {
    //             doc.setFontSize(9);
    //             doc.setFont('Arial', 'normal', 'bold');
    //             doc.setTextColor(0, 0, 0);
    //             let yPos = (doc as any).lastAutoTable.finalY + 10;
    //             const displayedKeys: any = [];
    //             let displayedKeysCount = 0;

    //             const columns: { header: string, dataKey: string }[] = [];


    //             const headers = Object.keys(stuAssessmentList[0])
    //                 .filter(key => !unwantedKeys.includes(key))
    //                 .map((key, index) => {
    //                     if (key !== "registerNo" && key !== "departmentRegisterNumber" && key !== "name" && !displayedKeys.includes(key)) {
    //                         const matchingCourse = CourseList.find(course => course.name === key);

    //                         if (matchingCourse) {
    //                             columns.push({ header: `${matchingCourse.shortName} - ${key}`, dataKey: key });
    //                             displayedKeys.push(key);
    //                             displayedKeysCount++;
    //                         }
    //                     }
    //                 });

    //             autoTable(doc, {
    //                 startY: yPos,
    //                 headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 8, },
    //                 bodyStyles: { textColor: [0, 0, 0], lineColor: [0, 0, 0], fontSize: 8 },
    //                 head: columns.map(col => [col.header]),
    //                 // body: rows,
    //                 theme: 'plain',
    //             });
    //         }
    //     });

    //     doc.save(fileName + '.pdf');
    // }


    // const csvData = stuAssessmentList.map((row, index) => {
    //     const cellContents = Object.keys(row)
    //         .filter((key) => !unwantedKeys.includes(key))
    //         .map((key) => (row[key] !== null ? row[key].toString() : "-"));

    //     // Create a row for each student
    //     const rowData = [
    //         index + 1,
    //         row["register_no"],
    //         row["department_register_number"],
    //         row["name"],
    //         ...cellContents,
    //     ];

    //     return rowData;
    // });

    // // Add header row to csvData
    // const headerRow = [
    //     'S.No', 'Reg.No', 'D.No.', 'Name of the student',
    //     ...headers,
    // ];

    // csvData.unshift(headerRow);


    return (
        <PageContainer title="Student Assessment">
            <Card title="Add Student Assessment">
                <div className='container m-0'>
                    <div className="row">

                        <div className="col-4">
                            <label className="mt-3 mb-3">Degree <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedDegree"
                                value={selectedDegreeId}
                                onChange={(e) => setSelectedDegreeId(e.target.value)}
                            >
                                <option value="" selected>Select Degree</option>
                                {selectedDegree.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {degreeIdError && <div className="text-danger">{degreeIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Discipline <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px", }}
                                name="selectedDiscipline"
                                value={selectedDisciplineId}
                                onChange={(e) => setSelectedDisciplineId(e.target.value)}
                            >
                                <option value="" selected>Select Discipline</option>
                                {selectedDiscipline.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {disciplineIdError && <div className="text-danger">{disciplineIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Course <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedCourse"
                                value={selectedCourseId}
                                onChange={(e) => setSelectedCourseId(e.target.value)}
                            >
                                <option value="" selected>Select Course</option>
                                {selectedCourse.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.name}
                                    </option>
                                ))}
                            </select>
                            {courseIdError && <div className="text-danger">{courseIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Phase <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedPhase"
                                value={selectedPhaseId}
                                onChange={(e) => setSelectedPhaseId(e.target.value)}
                            >
                                <option value="" selected>Select Phase</option>
                                {/* <option value="0">All Phase</option> */}
                                {selectedPhase.map((course) => (
                                    <option key={course.id} value={course.id}>
                                        {course.title}
                                    </option>
                                ))}
                            </select>
                            {phaseIdError && <div className="text-danger">{phaseIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Batch <span className="text-danger">*</span> : </label>
                            <select
                                className="form-control"
                                style={{ width: "100%", marginRight: "20px" }}
                                name="selectedBatch"
                                value={selectedBatchId}
                                onChange={(e) => setSelectedBatchId(e.target.value)}
                            >
                                <option value="" selected>Select Batch</option>
                                {last10Years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                            {batchIdError && <div className="text-danger">{batchIdError}</div>}
                        </div>

                        <div className="col-4">
                            <label className="mt-3 mb-3">Actual Contact Hours <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Contact Hours..." type="text" name="ContactHours" onChange={(e) => {
                                setContactHours(e.target.value);
                                setContactHoursError('');
                                setApiErrorMessage('');
                            }} value={contactHours} />
                            {contactHoursError && <div className="text-danger">{contactHoursError}</div>}
                        </div>

                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={filterStudent}
                                style={{ width: "10%", marginTop: "30px", marginBottom: "30px", float: "right" }}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{ width: "100%", marginTop: "20px" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Register No</th>
                                {/* <th>Phase</th> */}
                                <th>Contact Hours <span className="text-danger">*</span></th>
                                <th>Attended Hours <span className="text-danger">*</span></th>
                                <th>Mark <span className="text-danger">*</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {stuAssessmentTable?.map((StuAssessment, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{StuAssessment.registerNo}</td>
                                    {/* <td>{StuAssessment.phase}</td> */}
                                    <td>
                                        <input
                                            className="form-control"
                                            placeholder="Enter Contact Hours..."
                                            type="number"
                                            name={`contactHours-${index}`}
                                            onChange={(e) => handelContactHoursChange(index, e.target.value)}
                                            value={contactHoursArray[index]}
                                            style={{ width: '60%' }}

                                        />
                                        {contactHoursError && <div className="text-danger">{contactHoursError}</div>}
                                    </td>
                                    <td>
                                        <input
                                            className="form-control"
                                            placeholder="Enter Attendance Hour..."
                                            type="number"
                                            name={`attendence-${index}`}
                                            onChange={(e) => handelAttendenceChange(index, e.target.value)}
                                            value={attendenceArray[index]} style={{ width: "60%" }}
                                        />
                                        {attendenceError && <div className="text-danger">{attendenceError}</div>}
                                    </td>
                                    <td>
                                        <input
                                            className="form-control"
                                            placeholder="Enter Mark..."
                                            type="number"
                                            name={`mark-${index}`}
                                            onChange={(e) => handleMarkChange(index, e.target.value)}
                                            value={markArray[index] || ''} style={{ width: "60%" }}
                                        />
                                        {markError && <div className="text-danger">{markError}</div>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col d-flex justify-content-end" style={{ width: "95%" }}>
                    <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>
                        Clear
                    </button>
                    {/* {editFlag ? <button className="btn btn-success" onClick={updateHandler}>Update</button> : */}
                    <button className="btn btn-sm btn-success" onClick={addHandler}>Add</button>
                </div>
            </Card>

            <CollapseCard title="Student Assessment">
                <div className="row m-2 mb-3">

                    {/* <div className="col-4">
                        <label className="">Academic Year  : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="academicYearFilter"
                            value={academicYearFilter}
                            onChange={(e) => setAcademicYearFilter(e.target.value)}
                        >
                            {academicYearOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div> */}

                    {/* <div className="col-4">
                        <label className="">Semester  : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedBatch"
                            value={semester}
                            onChange={(e) => setSemester(e.target.value)}
                        >
                            <option value="" selected>Select Semester</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                        </select>
                    </div> */}

                    <div className="col-4">
                        <label className="">Phase : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedPhase"
                            value={selectedPhaseIdFilter}
                            onChange={(e) => setSelectedPhaseIdFilter(e.target.value)}
                        >
                            <option value="" selected>Select Phase</option>
                            {/* <option value="0">All Phase</option> */}
                            {selectedPhase.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.title}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-2">degree : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="degreeId"
                            value={degreeIdFilter}
                            onChange={(e) => setDegreeIdFilter(e.target.value)}
                        >
                            <option value="" selected>Select Degree</option>
                            {selectedDegree.map((deg) => (
                                <option key={deg.id} value={deg.id}>
                                    {deg.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-2">discipline : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="disciplineId"
                            value={disciplineIdFilter}
                            onChange={(e) => setDisciplineIdFilter(e.target.value)}
                        >
                            <option value="" selected>Select Discipline</option>
                            {selectedDiscipline.map((dis) => (
                                <option key={dis.id} value={dis.id}>
                                    {dis.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-2">Course : </label>
                        <select
                            className="form-control"
                            style={{ width: "100%", marginRight: "20px" }}
                            name="selectedCourseFilterId"
                            value={selectedCourseIdFilter}
                            onChange={(e) => setSelectedCourseIdFilter(e.target.value)}
                        >
                            <option value="" selected>Select Course</option>
                            {selectedCourse.map((dis) => (
                                <option key={dis.id} value={dis.id}>
                                    {dis.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-4">
                        <label className="mt-2 mb-2">batch : </label>
                        <select name="" id="" className='form-control' value={batchFilter} onChange={(e) => {
                            setBatchFilter(e.target.value)
                        }}>
                            <option value="">Select Batch</option>
                            {last10Years.map((ly => {
                                return <option key={ly} value={ly}>{ly}</option>
                            }))}
                        </select>
                    </div>
                    {/* <div className="col-4">
                        <button
                            type="button"
                            className="className='btn btn-sm btn-primary mt-5 mb-3"
                            onClick={fetchData}
                        >
                            Search
                        </button>
                    </div> */}

                    <div className="mt-1 mb-1 d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-sm btn-primary mt-5 mb-3"
                            style={{ marginRight: '10px' }}
                            onClick={fetchData}
                        >
                            Search
                        </button>

                        <button
                            type="button"
                            className="btn btn-sm btn-secondary mt-5 mb-3"
                            onClick={resetData}
                        >Reset
                        </button>
                    </div>



                </div>

                <div style={{margin: '0% 5% 3% 0%', float: 'right'}}>
                        <button
                            type="button"
                            onClick={handleDownloadPDF}
                            className="m-1 btn btn-sm btn-primary"
                        >
                            <Feather.Download /> PDF
                        </button>
                        <CSVLink
                            data={csvData}
                            filename="details_of_course.csv"
                            className=" btn btn-sm btn-primary"
                        >
                            <Feather.Download /> CSV
                        </CSVLink>
                    </div>

                <div className="container-fluid" style={{ width: "100%" }}>
                    <table className="table table-success table-striped">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>ID</th>
                                <th>Register No</th>
                                <th>Phase</th>
                                <th>Degree</th>
                                <th>Discipline</th>
                                <th>Discipline Course </th>
                                <th>Batch</th>
                                <th>Contact Hours</th>
                                <th>Attended Hours</th>
                                <th>Mark</th>
                                {/* <th>Created By</th>
              <th>Create Timestamp</th> */}
                                <th>Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentListFilter.map((sl, index) => (
                                <tr key={sl.id}>
                                    <td>{index + 1}</td>
                                    <td>{sl.id}</td>
                                    <td>{sl.registerNo}</td>
                                    <td>{getPhaseId(sl)}</td>
                                    <td>{getDegreeName(sl.degreeId)}</td>
                                    <td>{getDisciplineName(sl.disciplineId)}</td>
                                    <td>{getCourseId(sl)}</td>
                                    <td>{sl.batch}</td>
                                    <td>{sl.contactHours}</td>
                                    <td>{sl.attendedHours}</td>
                                    <td>{sl.mark}</td>
                                    {/* <td>{sl.createdBy}</td>
                <td>{sl.createTimestamp}</td> */}
                                    <td>
                                        <button className="btn btn-sm btn-primary" style={{ marginRight: '10px' }} onClick={() => openEditModel(sl.id, sl.registerNo, getDegreeName(sl.degreeId), getDisciplineName(sl.disciplineId), sl.phase, getCourseId(sl), sl.batch, sl.contactHours, sl.attendedHours, sl.mark)}>Edit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(sl.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </CollapseCard>
            <Modal show={editModelShow} onHide={() => setEditModelShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Student Assessment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-6">
                            <label className="mt-3 mb-3">Register No:</label>
                            <input className="form-control" type="text" value={editRegisterNo} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Degree:</label>
                            <input className="form-control" type="text" value={editDegreeId} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Discipline:</label>
                            <input className="form-control" type="text" value={editDisciplineId} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Course:</label>
                            <input className="form-control" type="text" value={editCourse} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Phase:</label>
                            <select
                                className="form-control"
                                // style={{ width: "100%", marginRight: "20px" }}
                                name="selectedPhase"
                                value={editPhase}
                                onChange={(e) => setSelectedPhaseId(e.target.value)}
                                disabled
                            >
                                <option value="" selected>Select Phase</option>
                                {/* <option value="0">All Phase</option> */}
                                {selectedPhase.map((phase) => (
                                    <option key={phase.id} value={phase.id}>
                                        {phase.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Batch:</label>
                            <input className="form-control" type="text" value={editbatch} readOnly />
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Actual Contact Hours <span className="text-danger">*</span> : </label>
                            <input className="form-control" placeholder="Enter Contact Hours..." type="text" name="ContactHours" onChange={(e) => {
                                setEditContactHours(e.target.value);
                                setContactHoursError('');
                            }} value={editContactHours} />
                            {contactHoursError && <div className="text-danger">{contactHoursError}</div>}
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Attendance Hour <span className="text-danger">*</span> : </label>
                            <input
                                className="form-control"
                                placeholder="Enter Attendance Hour..."
                                type="number"
                                name="Mark"
                                onChange={(e) => {
                                    setEditAttendence(e.target.value);
                                    setAttendenceError('');
                                }}
                                value={editAttendence}
                            />
                            {attendenceError && <div className="text-danger">{attendenceError}</div>}
                        </div>

                        <div className="col-6">
                            <label className="mt-3 mb-3">Mark <span className="text-danger">*</span> : </label>
                            <input
                                className="form-control"
                                placeholder="Enter Mark..."
                                type="number"
                                name="Mark"
                                onChange={(e) => {
                                    setEditMark(e.target.value);
                                    setMarkError('');
                                }}
                                value={editMark}
                            />
                            {markError && <div className="text-danger">{markError}</div>}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModelShow(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={saveEdit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Deletion Confirmation Modal */}
            <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDeleteConfirmation}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDeleteHandler}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageContainer>
    );
}

export default StuAssessment;

interface StuAssessmentType {
    id: number;
    registerNo: string;
    degreeId: string;
    disciplineId: string;
    disciplineCourseId: string;
    batch: string;
    mark: string;
    externalMaxMark: string;
    createdBy: string;
    createTimestamp: string;
    phase: string;
    contactHours: string;
    attendedHours: string;
}
